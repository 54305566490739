import React, { Component } from 'react';
import {Grid, Row, Col} from 'react-bootstrap';

class Head01 extends Component {

  render() {
    return (
      <div className="header01">
        <div className="container">
          <Grid>
            <Row className="show-grid">
              <Col sm={6} xs={12} className="pull-left">
                <div className="pull-left">
                  <i className="glyphicon glyphicon-picture titleicon"></i>
                </div>
                <div>
                  <p className="title1">Smart Maintenance Platform</p>
                  <p className="title2">Copyright Aero-Asahi Corporation. All Rights Reserved.</p>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Head01;
