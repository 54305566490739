import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import iconMenu from './images/menu_icon.svg';

//---------------------------------------------------------------------
// メニュー
function menuFormatter(cell, row) {
  return (
    <span><img src={iconMenu} width="24" height="24" alt="Menu" /></span>
  );
}
function dropdownFormatter(cell, row) {
  var epsglist = [
    {code:2443, name:"JGD2000 平面直角座標1系"},
    {code:2444, name:"JGD2000 平面直角座標2系"},
    {code:2445, name:"JGD2000 平面直角座標3系"},
    {code:2446, name:"JGD2000 平面直角座標4系"},
    {code:2447, name:"JGD2000 平面直角座標5系"},
    {code:2448, name:"JGD2000 平面直角座標6系"},
    {code:2449, name:"JGD2000 平面直角座標7系"},
    {code:2450, name:"JGD2000 平面直角座標8系"},
    {code:2451, name:"JGD2000 平面直角座標9系"},
    {code:2452, name:"JGD2000 平面直角座標10系"},
    {code:2453, name:"JGD2000 平面直角座標11系"},
    {code:2454, name:"JGD2000 平面直角座標12系"},
    {code:2455, name:"JGD2000 平面直角座標13系"},
    {code:2456, name:"JGD2000 平面直角座標14系"},
    {code:2457, name:"JGD2000 平面直角座標15系"},
    {code:2458, name:"JGD2000 平面直角座標16系"},
    {code:2459, name:"JGD2000 平面直角座標17系"},
    {code:2460, name:"JGD2000 平面直角座標18系"},
    {code:2461, name:"JGD2000 平面直角座標19系"},
    {code:6669, name:"JGD2011 平面直角座標1系"},
    {code:6670, name:"JGD2011 平面直角座標2系"},
    {code:6671, name:"JGD2011 平面直角座標3系"},
    {code:6672, name:"JGD2011 平面直角座標4系"},
    {code:6673, name:"JGD2011 平面直角座標5系"},
    {code:6674, name:"JGD2011 平面直角座標6系"},
    {code:6675, name:"JGD2011 平面直角座標7系"},
    {code:6676, name:"JGD2011 平面直角座標8系"},
    {code:6677, name:"JGD2011 平面直角座標9系"},
    {code:6678, name:"JGD2011 平面直角座標10系"},
    {code:6679, name:"JGD2011 平面直角座標11系"},
    {code:6680, name:"JGD2011 平面直角座標12系"},
    {code:6681, name:"JGD2011 平面直角座標13系"},
    {code:6682, name:"JGD2011 平面直角座標14系"},
    {code:6683, name:"JGD2011 平面直角座標15系"},
    {code:6684, name:"JGD2011 平面直角座標16系"},
    {code:6685, name:"JGD2011 平面直角座標17系"},
    {code:6686, name:"JGD2011 平面直角座標18系"},
    {code:32647, name:"UTM Zone 47N"},
    {code:32648, name:"UTM Zone 48N"},
    {code:32651, name:"UTM Zone 51N"},
    {code:32652, name:"UTM Zone 52N"},
    {code:32653, name:"UTM Zone 53N"},
    {code:32654, name:"UTM Zone 54N"},
    {code:32655, name:"UTM Zone 55N"},

  ]

if(this.props.state.ftype === "Ortho"){
  // 2020/01/15　TIFFTOTILEがUTMにEXEが対応していないため、UTMを除外する
  epsglist = epsglist.filter(n => n.code < 32647)} 

  var options = epsglist.map(
    (n)=>(
      <option key={n.code} value={n.code}>
        {n.name}
      </option>
    )
  );

  return (
    <select value={ row.epsg } name={ row.id }>
      { options }
    </select>
  );
}


//---------------------------------------------------------------------
let selectRow = {
  mode: 'checkbox',
  clickToSelect: false,
  //onSelect: this.onRowSelect.bind(this),
  //onSelectAll: this.onSelectAll.bind(this)
};

//---------------------------------------------------------------------
class GroupList extends Component {

  constructor(props, context){
    super(props, context);
    this.state = {
      info: [],    
      fCommExec: false}

    this.updateList(props.state.curpage)

    // var self = this;
    this.listchecked = props.listchecked
    selectRow["onSelect"] = this.onRowSelect.bind(this)
    selectRow["onSelectAll"] = this.onSelectAll.bind(this)
    
    this.columns = [{
      dataField: 'id',
      text: 'ID',
      hidden: true
    }, {
      dataField: 'name',
      text: 'グループ',
    }, {
      dataField: 'status',
      text: '状態',
    }, {
      dataField: 'updateDate',
      text: '更新日',
    }, {
      dataField: 'size',
      text: 'サイズ',
      formatter: this.props.myApp.sizeFormatter,
    }, {
      dataField: 'fileNum',
      text: 'ファイル数',
    }, {
      dataField: 'epsg',
      text: '座標系',
      formatter: dropdownFormatter.bind(this),
      events: {
        onChange: this.onDDLChange.bind(this)
      }
    }, {
      dataField: 'menu',
      text: ' ',
      align: 'center',  
      formatter: menuFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {

        }
      }
    }];

    this.props.parent.convertList = this // ここにこう書くのは微妙な気がする
  }
  componentDidMount(){
    this.intervalId = setInterval(() => {
      this.updateList(this.props.state.curpage);
    }, 30000); 
  }
  componentWillUnmount(){
    clearInterval(this.intervalId);
  }

  render() {
    let spinnerStyle = {display:'none'};
    if (this.state.fCommExec) {
      spinnerStyle = {display:'block', zIndex:'1000'};
    }

    return (
      <div className="convertList">
        <BootstrapTable
            keyField="id"
            data={ this.state.info }
            columns={ this.columns }
            bordered={ false }
            selectRow={ selectRow }
            hover
            condensed
            noDataIndication="ジョブが存在しません" 
         />
         <div className="spinner-loader" style={spinnerStyle}></div>
      </div>
    );
  }
  onRowSelect(row, isSelected, e) {
    this.listchecked[row.name] = isSelected
  }
  onSelectAll(isSelected, rows) {
    for (let i = 0; i < rows.length; i++) {
      this.listchecked[rows[i].name] = isSelected
    }
  }
  onDDLChange(e) {
    var info = this.state.info
    info[Number(e.target.name)].epsg = e.target.value
    this.setState({info: info})
  }
  updateList(curpage) {
    // this.listchecked;
    var newlist = this.getGroupListForDebug()

    var projectid = this.props.myApp.curProjectInfo.ProjectId;
    var groupid = this.props.myApp.loginInfo.User.SmpfGroups[this.props.myApp.curOrgIndex].SmpfGroup.SmpfGroupId;

    var getStatusExp = function(status) {
      if(status === "notproceed") {
        return "未処理"
      }
      if(status === "prepared") {
        return "準備中"
      }
      if(status === "processing") {
        return "処理中"
      }
      if(status === "completed") {
        return "完了"
      }
      if(status === "error") {
        return "エラー"
      }
      return "？"
    }

    var getJobtype = function(curpage){
      if(curpage === 0){
        return "convertToOctree";
      }
      if(curpage === 1){
        return "tiffToTile";
      }
    }

    var self = this;
    console.info(this.props.myApp.getFolderList(this.props.myApp.curProjectInfo.ProjectId,this.props.state.ftype,function(ret, json_group) {
      var groups = []
      for(var index=0; index < json_group.FolderInfo.length; index++) {
        groups.push(json_group.FolderInfo[index].FolderName)
      }
      self.setState({fCommExec:true});
      self.props.myApp.getStatus(projectid, groupid, getJobtype(curpage), groups, function(ret, json_status){
        setTimeout( function() {
          var info=[]
          for(var index=0; index < json_group.FolderInfo.length; index++) {
            var folder = json_group.FolderInfo[index]
            var item = {
              id: info.length,
              name: folder.FolderName,
              status: getStatusExp(json_status[folder.FolderName]),
              updateDate: folder.Property.UpdateDate.split(" ")[0],
              size: folder.FolderSize,
              fileNum: folder.FileCount,
              epsg: 2451,
              menu: true
            }
            info.push(item)
          }
          self.state.info = info
          self.setState({info: info})
          self.setState({fCommExec:false})
        }, 100);
      })
    }))
  }

  addListItem() {
    let obj = {id:this.state.info.length, name:"data"+this.state.info.length, status:"未処理", updateDate:"2019/01/01", size:"104 MB", fileNum:1, menu:true};
    var newlist = this.state.info.concat([obj])

    this.state.info = newlist
    this.setState({info: newlist})
  }

  getGroupListForDebug(){
    var list = [];

    let obj = {id:0, name:"root", status:"完了", updateDate:"2018/11/15", size:"3.27 GB", fileNum:6, menu:true};
    list.push(obj);

    obj = {id:1, name:"GROUP1", status:"実行中", updateDate:"2018/11/16", size:"2.31 GB", fileNum:4, menu:true};
    list.push(obj);

    obj = {id:2, name:"GROUP2", status:"未処理", updateDate:" ", size:"4.56 GB", fileNum:3, menu:true};
    list.push(obj);

    return list
  }
  
  }

export default GroupList;

