import React, { Component } from 'react';
import iconBatsu from './images/white_batsu.svg';
import GroupList from './groupList';
import iconPlus from './images/gray_plus.svg';
import iconUpdate from './images/re-load.svg';

class GroupMgmt extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      type: this.props.type,
      subTitle: "グループ設定",
      groups: this.props.groups,
      selectedIndex: -1,
      searchText: ''
    };

    this.groupList = this.props.groups;
    this.parent = this.props.parent;
  }

  onChange(e) {
    console.log("+++ e.target.value = " + e.target.value);
  }

  onClickClose = () => {
    // 閉じる
    this.parent.hideDialog(4);
  }

  onClickIcon(event) {
    let str = event.currentTarget.getAttribute('data-index');
    console.log("+++ data-index = " + str);
    let id = Number(str);
    if (id === 1) {
      // 追加
      this.parent.dispDialog(8);
    }
    else if (id === 2) {
      // 更新

    }
  }

  onChangeSearchText(event) {
    console.log("+++ searchText:" + event.target.value);
    let searchText = event.target.value;
    let arr = this.props.groups;
    if (arr) {
      this.groupList = [];
      for (let i = 0; i < arr.length; i++) {
        let info = arr[i];
        if (info.title.indexOf(searchText) > -1) {
          this.groupList.push(arr[i]);
        }
      }
    }

    this.setState({searchText: event.target.value});
  }

  render() {
    let Content = (() => <GroupList groupList={this.groupList} parent={this} myApp={this.props.myApp}/>);

    return (
      <div className="formMyDialog" style={{zIndex:'1000'}}>
        <div className="dialogHeader">
          <p className="dialogTitle1">グループの管理</p>
          <p className="dialogTitle2">{this.state.subTitle}</p>
          <img width="14" height="14" src={iconBatsu} 
               style={{position:'absolute', cursor:'pointer', top:'8px', right:'8px'}} alt="閉じる" data-index="1" 
               onClick={this.onClickClose.bind(this)}/>
        </div>
        
        <div className="dialogContent" style={{display:'block'}}>

          <div className="dialogToolbar">
            <div className="mgmtToolbarItem" data-index="1" onClick={this.onClickIcon.bind(this)}>
              <img width="20" height="20" src={iconPlus}   alt="グループ設定"/>
              <span>追加</span>
            </div>
            <div className="mgmtToolbarItem" data-index="2" onClick={this.onClickIcon.bind(this)}>
              <img width="20" height="20" src={iconUpdate} alt="更新"/>
              <span>更新</span>
            </div>
          </div>

          <div style={{marginTop:'8px',width:'100%'}}>
            <input className="form-control glyphicon" type="search" 
                   placeholder="名前でフィルター..." style={{padding:'4px 10px'}} 
                   onChange={this.onChangeSearchText.bind(this)}
                   value={this.state.searchText}/>
          </div>
          <div style={{margin:'8px 4px'}}>
            {this.groupList.length} 項目
          </div>

          <Content />
        </div>
      </div>
    );
  }
}

export default GroupMgmt;
