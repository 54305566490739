import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import icon05 from './images/05.png';  // 鍵

class ChangePassword extends Component {
  constructor(props){
    super(props);
    this.parent = this.props.parent;
    this.state = {
      inputedPass1: "",
      inputedPass2: ""
    };

    this.debugFlag = this.props.myApp.debugFlag; 
    this.loginInfo = this.props.loginInfo;
    this.userInfo = this.loginInfo.User;

    this.onChangePass1 = this.onChangePass1.bind(this);
    this.onChangePass2 = this.onChangePass2.bind(this);
    this.onClickChange = this.onClickChange.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
  }

  onChangePass1(event) {
    this.setState({inputedPass1: event.target.value});
  }

  onChangePass2(event) {
    this.setState({inputedPass2: event.target.value});
  }

  onClickChange(event) {
    // 変更
    if (this.state.inputedPass1 !== this.state.inputedPass2) {
      alert("入力された２つのパスワードが一致していません。\n入力しなおしてください。");
      return;
    }
    if (this.state.inputedPass1.length <= 0) {
      alert("新しいパスワードが未入力です。\n空のパスワードは許可されていません。");
      return;
    }

    let url = this.props.myApp.apiUrl + "/password/changepassword";
    let param = {};
    param.id = this.userInfo.Id;
    param.username = this.userInfo.UserName;
    param.prepassword = this.userInfo.passWord;
    param.currentpassword = this.state.inputedPass1;

    let postData = JSON.stringify(param);
    // console.log(postData);

    let self = this;
    if (this.debugFlag) {
      alert("--- 変更したつもり。---\n" + postData);
      self.parent.setState({changePasswordVisible: false});
      return;
    }

    let token = 'Bearer ' + this.loginInfo.token;

    fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: postData

    }).then(function(response) {
        if (response.ok) {
          self.parent.setState({changePasswordVisible: false});
          alert('パスワードを変更しました。');
          return;
        }
        throw new Error('パスワードの変更ができませんでした。');

    }).catch((err) => {
        alert(err);
        self.parent.setState({changePasswordVisible: false});
    });

  }

  onClickCancel(event) {
    // キャンセル
    this.parent.setState({changePasswordVisible: false});
  }

  render() {
    return (
      <div id="menu" className="my_menu" style={{textAlign:'left', zIndex:'1000', padding:'4px'}}>
        <table><tbody style={{verticalAlign:'middle'}}>
        <tr>
          <td><img src={icon05} width="20" height="20" alt="パスワード変更"/></td>
          <td style={{fontSize:'16px', display:'inline-block', padding:'4px'}}>新しいパスワード</td>
        </tr>
        <tr>
          <td> </td>
          <td><input type="password" className="form-control" name="Password" placeholder="新しいパスワード" 
                      style={{width:'220px', padding:'4px', marginLeft:'0'}} onChange={this.onChangePass1} value={this.state.inputedPass1} /></td>
        </tr>
        <tr>
          <td><img src={icon05} width="20" height="20" alt="パスワード変更"/></td>
          <td style={{fontSize:'16px', display:'inline-block', padding:'4px'}}>もう一度入力してください</td>
        </tr>
        <tr>
          <td> </td>
          <td><input type="password" className="form-control" name="Password" placeholder="新しいパスワード" 
                      style={{width:'220px', padding:'4px', marginLeft:'0'}} onChange={this.onChangePass2} value={this.state.inputedPass2} /></td>
        </tr>
        <tr>
          <td> </td>
          <td style={{padding:'12px 4px 4px 0px'}}>
            <Button bsStyle="primary" bsSize="small" onClick={this.onClickChange}>変更</Button>
            <Button bsSize="small" style={{marginLeft:'12px'}} onClick={this.onClickCancel}>キャンセル</Button>
          </td>
        </tr>
        </tbody></table>
      </div>
    );
  }

}

export default ChangePassword;

