export const msalConfig = {
    auth: {
        clientId: "d6bf469f-4cb9-47b5-b41f-9d077b6aee1b",
        authority: "https://login.microsoftonline.com/9a047171-61a0-42a2-b7b5-01b5ae9cd04b",
        redirectUri: "https://prod-3dviewer.azurewebsites.net/" // prod-3dviewer
        //redirectUri: "https://smpf-sso-test.azurewebsites.net/3dviewer/" // サンプルサイト
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};
