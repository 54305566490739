import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import iconBatsu from './images/white_batsu.svg';

class GroupAdd extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      type: this.props.curpage,
      subTitle: "グループの管理",
      groups: this.props.groups,
      inputedText: ''
    };

    this.parent = this.props.parent;
    this.handleChange = this.handleChange.bind(this);
  }

  onChange(e) {
    console.log("+++ e.target.value = " + e.target.value);
  }

  onClickClose = () => {
    // 閉じる
    this.parent.hideDialog(8);
  }

  onClickSave = () => {
    // 保存
  }

 getValidationState() {
    const length = this.state.inputedText.length;
    if (length > 1) return 'success';
    return null;
  }

  handleChange(e) {
    this.setState({ inputedText: e.target.value });
  }

  render() {
    return (
      <div className="formMyDialog" style={{right:'303px', zIndex:'1001'}}>
        <div className="dialogHeader">
          <p className="dialogTitle1">グループの作成</p>
          <p className="dialogTitle2">{this.state.subTitle}</p>
          <img width="14" height="14" src={iconBatsu} 
               style={{position:'absolute', cursor:'pointer', top:'8px', right:'8px'}} alt="閉じる" data-index="1" 
               onClick={this.onClickClose.bind(this)}/>
        </div>
        
        <div className="dialogContent" style={{display:'block'}}>

          <div style={{marginTop:'32px',width:'100%'}}>

            <FormGroup controlId="formBasicText" validationState={this.getValidationState()}>

              <ControlLabel>
                <span className="glyphicon glyphicon-asterisk" aria-hidden="true" style={{display:'inline-block', fontSize:'16px', color:'red'}}></span>
                <span style={{display:'inline-block', marginLeft:'4px'}}>グループ名</span>
              </ControlLabel>

              <FormControl style={{width:'260px'}}
                type="text"
                value={this.state.inputedText}
                placeholder="グループ名を入力してください"
                onChange={this.handleChange}
              />            
            </FormGroup>

          </div>
        </div>
        <div className="dialogFooter" style={{padding:'12px 24px'}}>
          <Button bsStyle="primary" onClick={this.onClickSave}>作 成</Button>
        </div>
      </div>
    );
  }
}

export default GroupAdd;
