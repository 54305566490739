import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import iconMenu from './images/menu_icon.svg';
import iconFile from './images/simple_file.svg';

const selectRow = {
  mode: 'checkbox',
  clickToSelect: false
};

const formatter = new Intl.NumberFormat('ja-JP');

//---------------------------------------------------------------------
// 小数点以下ｎ桁の丸め関数です
function round(num, n) {
  var tmp = Math.pow(10, n);
  return Math.round(num * tmp) / tmp;
}

//---------------------------------------------------------------------
// メニュー
function menuFormatter(cell, row) {
  return (
    <span style={{cursor:'pointer'}}><img src={iconMenu} width="24" height="24" alt="Menu" /></span>
  );
}

//---------------------------------------------------------------------
// 名前
function nameFormatter(cell, row) {
  return (
    <div>
      <img src={iconFile} width="20" height="20" alt="File" style={{display:'inline-block', marginRight:'10px'}}/>
      <span style={{display:'inline-block', verticalAlign:'bottom'}}>{row.FileName}</span>
    </div>
  );
}

//---------------------------------------------------------------------
// ファイル・サイズ
function sizeFormatter(cell, row) {
  let limitKB = 1024;
  let limitMB = 1024 * 1024;
  let limitGB = limitMB * 1024;
  let limitTB = limitGB * 1024;
  let str = '';
  let size = 0;
  if (row.FileSize < limitKB) {
    size = row.FileSize;    
    str = size.toString();
    str = formatter.format(str) + " Byte";
  }
  else if (row.FileSize < limitMB) {
    // KB
    size = round(row.FileSize / 1024.0, 1);    
    str = size.toString();
    str = formatter.format(str) + " KB";
  }
  else if (row.FileSize < limitGB) {
    // MB
    size = round(row.FileSize / limitMB, 2);    
    str = size.toString();
    str = formatter.format(str) + " MB";
  }
  else if (row.FileSize < limitTB) {
    // GB
    size = round(row.FileSize / limitGB, 3);    
    str = size.toString();
    str = formatter.format(str) + " GB";
  }
  else {
    // TB
    size = round(row.FileSize / limitTB, 3);    
    str = size.toString();
    str = formatter.format(str) + " TB";
  }
  return (
    <div>{str}</div>
  );
}

//---------------------------------------------------------------------
class PointCloudList extends Component {

  constructor(props, context){
    super(props, context);

    this.info = this.props.info;
    this.parent = this.props.parent;
    // var self = this;
    
    this.columns = [{
      dataField: 'id',
      text: 'ID',
      hidden: true
    }, {
      dataField: 'FileName',
      text: '名前',
      formatter: nameFormatter
    }, {
      dataField: 'FileUrl',
      text: 'URL',
      hidden: true
    }, {
      dataField: 'FileSize',
      text: 'サイズ',
      formatter: sizeFormatter
    }, {
      dataField: 'GroupName',
      text: 'グループ'
    }, {
      dataField: 'menu',
      text: ' ',
      align: 'center',  
      formatter: menuFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("+ rowIndex = " + rowIndex);
          this.parent.onClickRowMenu(e, rowIndex, row);
        }
      }
    }];

    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        console.log(`clicked on row with index: ${rowIndex}`);
      },
      onMouseEnter: (e, row, rowIndex) => {
        // console.log(`enter on row with index: ${rowIndex}`);
      }
    };

  }
  render() {
    return (
      <div className="pointCloudFileList">
        <BootstrapTable
            keyField="id"
            data={ this.info }
            columns={ this.columns }
            bordered={ false }
            rowEvents={ this.rowEvents } 
            selectRow={ selectRow }
            hover
            condensed
            noDataIndication="ファイルが存在しません" 
         />
      </div>
    );
  }
}

export default PointCloudList;
