import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Iframe from 'react-iframe'
import Head02 from './head02';

//--- 2D Viewer -----------------------------------------------
class Viewer2d extends Component {

  constructor(props, context){
    super(props, context);

    this.state = {

    };

    this.viewerUrl = this.props.myApp.viewer2dUrl;
  }

  //--- ログアウト --------------------------------------------
  logout() {
    this.props.myApp.logout();
    this.props.history.push('/');
  }

  //--- ポータル画面へ移動 ------------------------------------
  toPortal() {
    this.props.history.push('/portal/' + this.props.serviceId);
  }

  //--- プロジェクト一覧画面へ移動 ------------------------------------
  toDataSetList() {
    this.props.history.push("/dataSetList");
  }

  //--- 描画 --------------------------------------------------
  render() {
    return (
      <div className="App">

        <Head02 onLogout={this.logout.bind(this)} toPortal={this.toDataSetList.bind(this)} {...this.props}/>
        <div className="divideline clearfix"></div>

        <div className="contents_center" style={{margin:'0', padding:'0', bottom:'0px', overflow:'hidden'}}>
          <Iframe url={this.viewerUrl} width="100%" height="100%" />
        </div>
      </div>
    );
  }

}

export default withRouter(Viewer2d);


