import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import iconMenu from './images/menu_icon.svg';
import iconGroup from './images/iconGroup.png';

//---------------------------------------------------------------------
// メニュー
function menuFormatter(cell, row) {
  if (row.config) {
    return (
      <span><img src={iconMenu} width="24" height="24" alt="Menu" /></span>
    );
  }

  return (
    <span> </span>
  );
}

//---------------------------------------------------------------------
// 名前
function nameFormatter(cell, row) {
  return (
    <div style={{width:'160px', margin:'0'}}>
      <img src={iconGroup} width="24" height="24" alt="File" style={{display:'inline-block', marginLeft:'4px', marginRight:'10px'}}/>
      <span style={{display:'inline-block', verticalAlign:'bottom'}}>{row.name}</span>
    </div>
  );
}

//---------------------------------------------------------------------
class GroupList extends Component {

  constructor(props, context){
    super(props, context);

    this.list = this.props.groupList;
    this.info = [];

    let obj = {id:-1, name:"root", config:false};
    this.info.push(obj);
    for (let i = 0; i < this.list.length; i++) {
      let info = this.list[i];
      obj = {id:info.id, name:info.title, config:true};
      this.info.push(obj);
    }

    // var self = this;
    
    this.columns = [{
      dataField: 'id',
      text: 'ID',
      hidden: true
    }, {
      dataField: 'name',
      text: '名前',
      formatter: nameFormatter
    }, {
      dataField: 'menu',
      text: ' ',
      align: 'center',  
      formatter: menuFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {

        }
      }
    }];

  }
  render() {
    return (
      <div className="groupList">
        <BootstrapTable
            keyField="id"
            data={ this.info }
            columns={ this.columns }
            bordered={ false }
            hover
            condensed
            noDataIndication="グループが存在しません" 
         />
      </div>
    );
  }
}

export default GroupList;

