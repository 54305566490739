import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import iconMenu from './images/menu_icon.svg';
import iconFile from './images/simple_file.svg';

const selectRow = {
  mode: 'checkbox',
  clickToSelect: false
};

const formatter = new Intl.NumberFormat('ja-JP');

//---------------------------------------------------------------------
// 小数点以下ｎ桁の丸め関数です
function round(num, n) {
  var tmp = Math.pow(10, n);
  return Math.round(num * tmp) / tmp;
}

//---------------------------------------------------------------------
// 名前
function nameFormatter(cell, row) {
  if (row.isGroup) {
    return (
      <div>
        <span style={{display:'inline-block', width:'12px'}}></span>
        <span style={{display:'inline-block', verticalAlign:'bottom', fontSize:'18px'}}>{row.FileName}</span>
      </div>  
    );
  }
  return (
    <div>
      <img src={iconFile} width="20" height="20" alt="File" style={{display:'inline-block', marginRight:'10px'}}/>
      <span style={{display:'inline-block', verticalAlign:'bottom'}}>{row.FileName}</span>
    </div>
  );
}

//---------------------------------------------------------------------
// メニュー
function menuFormatter(cell, row) {
  return (
    <span><img src={iconMenu} width="24" height="24" alt="Menu" /></span>
  );
}

//---------------------------------------------------------------------
// ファイル・サイズ
function sizeFormatter(cell, row) {
  let limitKB = 1024;
  let limitMB = 1024 * 1024;
  let limitGB = limitMB * 1024;
  let limitTB = limitGB * 1024;
  let str = '';
  let size = 0;
  if (row.FileSize < limitKB) {
    size = row.FileSize;    
    str = size.toString();
    str = formatter.format(str) + " Byte";
  }
  else if (row.FileSize < limitMB) {
    // KB
    size = round(row.FileSize / 1024.0, 1);    
    str = size.toString();
    str = formatter.format(str) + " KB";
  }
  else if (row.FileSize < limitGB) {
    // MB
    size = round(row.FileSize / limitMB, 2);    
    str = size.toString();
    str = formatter.format(str) + " MB";
  }
  else if (row.FileSize < limitTB) {
    // GB
    size = round(row.FileSize / limitGB, 3);    
    str = size.toString();
    str = formatter.format(str) + " GB";
  }
  else {
    // TB
    size = round(row.FileSize / limitTB, 3);    
    str = size.toString();
    str = formatter.format(str) + " TB";
  }
  return (
    <span>{str}</span>
  );
}

//---------------------------------------------------------------------
class GeneralFileGroupList extends Component {

  constructor(props, context){
    super(props, context);
    let arr = this.props.info;

    arr.sort( function(a,b) {
      if(a.GroupName < b.GroupName) return -1;
      if(a.GroupName > b.GroupName) return 1;
      return 0;
    });

    this.info = [];
    this.parent = this.props.parent;

    this.columns = [{
      dataField: 'id',
      text: 'ID',
      hidden: true
    }, {
      dataField: 'FileName',
      text: '名前',
      formatter: nameFormatter
    }, {
      dataField: 'FileUrl',
      text: 'URL',
      hidden: true
    }, {
      dataField: 'FileSize',
      text: 'サイズ',
      formatter: sizeFormatter
    }, {
      dataField: 'GroupName',
      text: 'グループ'
    }, {
      dataField: 'menu',
      text: ' ',
      align: 'center',  
      formatter: menuFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.parent.onClickRowMenu(e, rowIndex, row);
        }
      }
    }];

    let groups = [];
    let index = 0;

    if (arr.length === 0) {
      return;
    }

    let curGroup = arr[0].GroupName;
    let name = curGroup;
    let size = arr[0].FileSize;
    if (curGroup.length === 0) {
      name = "root";
    }
    let obj = {id:0, FileName:name, FileUrl:"", FileSize:size, GroupName:curGroup, menu:true, isGroup:true};

    for (let i = 1; i < arr.length; i++) {
      if (curGroup !== arr[i].GroupName) {
        groups.push(obj);

        curGroup = arr[i].GroupName;
        size = arr[i].FileSize;
        obj = {id:0, FileName:curGroup, FileUrl:"", FileSize:arr[i].FileSize, GroupName:curGroup, menu:true, isGroup:true};

      } else {
        obj.FileSize += arr[i].FileSize;

        if (i === arr.length - 1) {
          groups.push(obj);
        }
      }
    }

    for (let i = 0; i < groups.length; i++) {
      obj = groups[i];
      obj.id = index;
      this.info.push(obj);
      index += 1;

      let flag = false;
      for (let j = 0; j < arr.length; j++) {
        if (!flag) {
          if (arr[j].GroupName !== obj.GroupName) {
            continue;
          }
          flag = true;
          arr[j].id = index;
          this.info.push(arr[j]);
          index += 1;
        }
        else {
          if (arr[j].group === obj.group) {
            arr[j].id = index;
            this.info.push(arr[j]);
            index += 1;
          }
          else {
            break;
          }
        }
      }
    }
  }

  render() {
    return (
      <div className="pointCloudFileList">
        <BootstrapTable
            keyField="id"
            data={ this.info }
            columns={ this.columns }
            bordered={ false }
            selectRow={ selectRow }
            hover
            condensed
            noDataIndication="ファイルが存在しません" 
         />
      </div>
    );
  }
}

export default GeneralFileGroupList;
