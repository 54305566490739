import React, { Component } from 'react';
import {Grid, Row, Col} from 'react-bootstrap';
import MyMenu from './myMenu';
import ChangePassword from './changePassword';
import ProfileDialog from './profileDialog';

import icon01 from './images/01.png';
import icon02 from './images/02.png';
import icon03 from './images/03.png';
import icon04 from './images/04.png';
import icon07 from './images/07.png';

//--- ヘッダー -----------------------------------------------------------------
class Head02 extends Component {

  constructor(props, context){
    super(props, context);

    if (this.props.loginInfo) {
      let userInfo = this.props.loginInfo.User;
      let orgInfo = userInfo.SmpfGroups[this.props.orgIndex];

      this.state = {
          userName: userInfo.DisplayName,
          orgName:  orgInfo.SmpfGroup.SmpfGroupName,
          mailAddr: "",
          popupMenuVisible: false,
          changePasswordVisible: false,
          profileDlgVisible: false,
          orgIndex: this.props.orgIndex
      };  
    } else {
      this.state = {
        userName: '',
        orgName:  '',
        mailAddr: '',
        popupMenuVisible: false,
        orgIndex: 0
      };  
      let self = this;
      setTimeout(function() {
        self.props.onLogout();
      }, 0);
    }

    this.dispMyMenu = this.dispMyMenu.bind(this);
    this.onClickMenu = this.onClickMenu.bind(this);
    this.onSelectOrgH02 = this.onSelectOrgH02.bind(this);

    this.getOrgInfoCB = this.getOrgInfoCB.bind(this);

    this.toPortal = this.props.toPortal;
    this.onSelectOrgDsl = this.props.onSelectOrgDsl;
    this.onSelectOrgCB = this.props.onSelectOrgCB;
  }

  dispMyMenu(event) {
    // ポップアップメニューを表示する。
    if (this.state.popupMenuVisible) {
      this.setState({popupMenuVisible: false, changePasswordVisible: false, profileDlgVisible: false});
    } else {
      this.setState({popupMenuVisible: true,  changePasswordVisible: false, profileDlgVisible: false});
    }
  }

  onClickMenu(event) {
    let str = event.currentTarget.getAttribute('data-index');
    let index = Number(str);
    if (index === 0) {
      // 設定
    }
    else if (index === 1) {
      // フィードバック
    }
    else if (index === 2) {
      // ヘルプ
    }
    else if (index === 3) {
      // ポータルへ
      if (this.toPortal) {
        this.toPortal();
      }
    }
  }
  //--- 組織の選択 --------------------------------------------
  onSelectOrgH02(index) {
//    if (this.props.onSelectOrg) {
//      this.props.onSelectOrg(index);
//    }
//    if (this.onSelectOrgDsl) {
//      this.onSelectOrgDsl(index);
//    }

//    let userInfo = this.props.loginInfo.User;
//    let orgInfo = userInfo.SmpfGroups[this.props.orgIndex];
//    // let orgInfo = this.props.userInfo[index];
//
//    // this.setState({popupMenuVisible: false, orgIndex: index, orgName: orgInfo.org_name, mailAddr:orgInfo.mail});
//    this.setState({popupMenuVisible: false, orgIndex: index, orgName: orgInfo.SmpfGroup.SmpfGroupName, userName:userInfo.DisplayName});

    let userInfo = this.props.loginInfo.User;
    let orgInfo = userInfo.SmpfGroups[index];
    this.setState({popupMenuVisible: false, orgIndex: index, orgName: orgInfo.SmpfGroup.SmpfGroupName, userName:userInfo.DisplayName});

    this.props.myApp.getProjectList(index, this.getOrgInfoCB);
  }

  getOrgInfoCB() {
    if (this.props.onSelectOrg) {
      this.props.onSelectOrg(this.state.orgIndex);
    }
    if (this.onSelectOrgDsl) {
      this.onSelectOrgDsl(this.state.orgIndex);
    }
    if (this.onSelectOrgCB) {
      this.onSelectOrgCB(this.state.orgIndex);
    }
  }

  render() {
    let imgStyle1 = {margin:"8px 0px 0px 6px"};
    let imgStyle2 = {margin:"1px 0px 0px 0px"};
    let imgStyle3 = {margin:"12px 8px 0px"};
    let txtStyle1 = {height:"18px"};
    let txtStyle2 = {height:"16px"};

    if (this.state.orgName === '') {
      return (<div></div>);
    }

    let Content = null;
    if (this.state.popupMenuVisible) {
      Content = (() => <MyMenu onLogout={this.props.onLogout} 
        onSelectOrgH02={this.onSelectOrgH02} {...this.props} orgIndex={this.state.orgIndex} parent={this} myApp={this.props.myApp}/>);
    } 
    else if (this.state.changePasswordVisible) {
      Content = (() => <ChangePassword parent={this} {...this.props} myApp={this.props.myApp}/>);
    }
    else if (this.state.profileDlgVisible) {
      Content = (() => <ProfileDialog parent={this} {...this.props} myApp={this.props.myApp}/>);
    }
    else {
      Content = (() => <div style={{display:'none'}}></div>);
    }

    return (
      <div className="header02">
        <div className="container">
          <Grid>
            <Row className="show-grid">
              <Col sm={6} xs={12} className="pull-left">
                <div className="pull-left">
                  <i className="glyphicon glyphicon-picture titleicon"></i>
                </div>
                <div>
                  <p className="title1">Smart Maintenance Platform</p>
                  <p className="title2">Copyright Aero-Asahi Corporation. All Rights Reserved.</p>
                </div>
              </Col>
              <Col sm={6} xs={12} className="right_menu">
                <div className="pull-right menu_profeel_div">
                  <div className="menu_icon" data-index='0'>
                    <img src={icon01} width="24" height="24" alt="設定" style={imgStyle1} />
                  </div>
                  <div className="menu_icon" data-index='1'>
                    <img src={icon02} width="24" height="24" alt="フィードバック" style={imgStyle1} />
                  </div>
                  <div className="menu_icon" data-index='2'>
                    <img src={icon03} width="24" height="24" alt="ヘルプ" style={imgStyle1} />
                  </div>

                  <div className="menu_profeel" onClick={this.dispMyMenu}>
                    <div className="menu_profeel_item" style={txtStyle1}>{this.state.userName}</div>
                    <div className="menu_profeel_item" style={txtStyle2}>{this.state.orgName}</div>
                  </div>

                  <div className="menu_profeel_icon"  onClick={this.dispMyMenu}>
                    <img src={icon04} width="36" height="36" alt="ユーザー名／組織名" style={imgStyle2} />
                  </div>

                </div>
                <div className="pull-right" data-index='3' onClick={this.onClickMenu} style={{cursor:'pointer'}}>
                    <img src={icon07} width="32" height="32" alt="ポータル" style={imgStyle3} />
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
        <Content /> {/* ポップアップ・メニュー */}
      </div>
    );
  }
}

export default Head02;
