import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Head02 from './head02';
import Footer from './footer';

import icon01 from './images/side01.png';
// import icon02 from './images/side02.png';
import icon03 from './images/side03.png';
// import icon04 from './images/side04.png';
// import icon05 from './images/side05.png';
// import icon06 from './images/side06.png';
// import icon07 from './images/side07.png';
// import icon08 from './images/side08.png';

import item01 from './images/item01.svg';
import item02 from './images/item02.svg';
// import item03 from './images/item03.svg';
import item04 from './images/item04.svg';
import item05 from './images/item05.svg';

//--- サイド・バー ----------------------------------------------------
class SideNav extends Component {
  constructor(props, context){
    super(props, context);
    this.state = {
        selectedMenu: 0
    };
  }

  getSideBar() {
    return (
      <div id="sidebar-wrapper">
        <ul className="sidebar-nav">
          <li className="sidebar-brand selected">
            <div><img src={icon01} alt="サービス"/><span>サービス</span></div>
          </li>
        </ul>
      </div>
    )
  }

  render() {
    return (
      <div style={{overflow:'hidden', top:'0', left:'0', width:'250px', height:'100%'}} id="wrapper">
        {this.getSideBar()}
      </div>
    );
  }
}

//--- ポータル・ページ ------------------------------------------------
class PagePortal extends Component {

  constructor(props, context){
    super(props, context);

    this.state = {
      smpfEnable: false,
      calsokEnable: false,
      waterEnable: false,
      good3dEnable: false,
      fCommExec: false
    };

    this.myApp = this.props.myApp;    
  }

  //-----------------------------------------------------------
  componentDidMount() {
    document.documentElement.lang = "ja"; 
    if (!this.myApp.loginInfo) {
      if (!this.myApp.restoreUserInfo()) {
        // ログイン情報が無い
        this.logout();
        return;
      }
      else {
        // ログイン情報が残っていた
        var self = this;
        setTimeout(function() {
          self.myApp.restoreUserInfo();
          self.props.history.push('/portal/1');
        }, 0);
        return;
      }
    }
    this.onSelectOrgCB(this.myApp.curOrgIndex);
  }

  //--- 組織変更 ---
  onSelectOrgCB(index) {
    let userInfo = this.myApp.loginInfo.User;
    let orgInfo = userInfo.SmpfGroups[index];
    let apps = orgInfo.WebApps;
    let flags = [false, false, false, false];
    for (let i = 0; i < apps.length; i++) {
      if (apps[i].AppName === "smpf") {
        flags[0] = true;
      }
      else if (apps[i].AppName === "calsok") {
        flags[1] = true;
      }
      else if (apps[i].AppName === "水中ロボット") {
        flags[2] = true;
      }
      else if (apps[i].AppName === "good-3d") {
        flags[3] = true;
      }    
    }
    this.setState({smpfEnable:flags[0], calsokEnable:flags[1], waterEnable:flags[2], good3dEnable:flags[3]});
  }

  //--- データセット一覧へ ----
  onClickSmpf() {
    //--- SMPF ---
    let wdiv = document.getElementById('contents_center');
    this.props.myApp.clientHeight = wdiv.clientHeight;
    this.props.onSelectService(1);
    this.props.history.push('/dataSetList');
  }

  onClickRov() {
    //--- 水中ロボット ---
    this.setState({fCommExec:true});
    this.myApp.moveToUnderwaterRobot();
  }

  //--- ログアウト ------------
  logout() {
    this.props.myApp.logout();
    this.props.history.push('/');
  }

  render() {
    let styleSmpf = '1.0';
    if (!this.state.smpfEnable) {
      styleSmpf = '0.5';
    }
    let styleCalsok = '1.0';
    if (!this.state.calsokEnable) {
      styleCalsok = '0.5';
    }
    let styleWater = '1.0';
    if (!this.state.waterEnable) {
      styleWater = '0.5';
    }
    let styleGood3d = {};
    if (!this.state.good3dEnable) {
      styleGood3d = '0.5';
    }

    let spinnerStyle = {display:'none'};
    if (this.state.fCommExec) {
      spinnerStyle = {display:'block', zIndex:'100'};
    }

    return (
      <div className="App">

        <Head02 onLogout={this.logout.bind(this)} onSelectOrgCB={this.onSelectOrgCB.bind(this)} {...this.props}/>
        <div className="divideline clearfix"></div>

        <div className="contents_center" id="contents_center">
          <SideNav />
          <div className="portalContent">
            <div className="currentAccount" style={{overflow:'hidden', textAlign:'left', verticalAlign:'middle'}}>
              <table><tbody><tr>
                {/* <td><img src={icon03} style={{margin:'4px 12px 0px', float:'left'}} alt="current account" /></td>
                <td style={{paddingTop:'8px'}}><span style={{fontSize:'18px'}}>現在のアカウント：</span></td> */}
                <td><img src={icon01} style={{margin:'4px 12px 0px', float:'left'}} alt="service" /></td>
                <td style={{paddingTop:'8px'}}><span style={{fontSize:'18px'}}>サービス </span></td>
              </tr></tbody></table>
            </div>
            <div className="labelCyan">サービスを選択してください</div>

            <div style={{marginTop:'44px'}}>
              <div className="servicePanel">
                <div className="serviceItem" onClick={this.onClickSmpf.bind(this)} style={{opacity:styleSmpf}}>
                  {/* <img src={item01} width='192' height='192' alt="SMPF"/> */}
                  {/* <p>SMPF</p> */}
                  {/* 2020.09 名称の変更対応 */}
                  <img src={item05} width='192' height='192' alt="Good-3D"/>
                  <p>Good-3D</p>
                </div>
                <div className="serviceItem"  style={{opacity:styleCalsok}}>
                  <img src={item02} width='192' height='192' alt="CalSok"/>
                  <p>CalSok</p>
                </div>
                <div className="serviceItem" onClick={this.onClickRov.bind(this)} style={{opacity:styleWater}}>
                  <img src={item04} width='192' height='192' alt="水中ロボット"/>
                  <p>水中ロボット</p>
                </div>
                {/* <div className="serviceItem"  style={{opacity:styleGood3d}}>
                  <img src={item05} width='192' height='192' alt="Good-3D"/>
                  <p>Good-3D</p>
                </div> */}
              </div>
            </div>
          </div>

        </div>

        <div className="spinner-loader" style={spinnerStyle}></div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(PagePortal);

