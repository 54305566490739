import React, { Component } from 'react';
import iconBatsu from './images/white_batsu.svg';
import ConvertList from './convertList';

import iconUpdate from './images/re-load.svg';
import iconProperty from './images/property.svg';

class GroupMgmt extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ftype:this.props.ftype,
      curpage: this.props.curpage,
      subTitle: this.props.subTitle,
      groups: this.props.groups,
      selectedIndex: -1,
      searchText: ''
    };

    this.listchecked = {}

    this.parent = this.props.parent;
  }

  onChange(e) {
    console.log("+++ e.target.value = " + e.target.value);
  }

  onClickClose = () => {
    // 閉じる
    this.parent.hideDialog(32);
  }

  onClickIcon(event) {
    let str = event.currentTarget.getAttribute('data-index');
    console.log("+++ data-index = " + str);
    let id = Number(str);
    if (id === 1) {
      // 変換実行
      var groups = []
      var isListChecked = false;
      for(var k in this.listchecked) {
        if(this.listchecked[k]) {
          groups.push(k);
          isListChecked = true;
        }
      }
      console.info(groups)

      if(!isListChecked){
        alert("変換するデータを選択してください。");
      return;
      }

      let epsgcodes = {}
      let info = this.convertList.state.info
      for(var i=0; i<info.length;i++) {
        epsgcodes[info[i].name] = info[i].epsg
      }
      console.info(epsgcodes)

      var projectid = this.props.myApp.curProjectInfo.ProjectId;
      var groupid = this.props.myApp.loginInfo.User.SmpfGroups[this.props.myApp.curOrgIndex].SmpfGroup.SmpfGroupId;
  
      for(var i = 0; i < groups.length; i++) {
        var name = groups[i]
        var param={"epsgcode": epsgcodes[name], "minlevel":5, "maxlevel":18} //TODO:levelの設定
        var syncexcute = async ()=>{await this.props.myApp.executeConvert(projectid, groupid, name, "tiffToTile", param)};
        setTimeout(syncexcute(), 500); //5ミリ秒待つ
      }      
      alert("処理を受け付けました");

      for(var k in this.listchecked) {
        this.listchecked[k] = false;
      }
      this.convertList.updateList(this.state.curpage);
      this.setState({groups: this.groups});
    }
    else if (id === 2) {
      // 更新
      this.convertList.updateList(this.state.curpage)
    }
    else if (id === 3) {
      // プロパティ

      // DEBUG：表示テストの為1行追加する
      // this.convertList.addListItem()
    }
  }

  render() {
    let Content = (() => <ConvertList parent={this} listchecked={this.listchecked} myApp={this.props.myApp} state={this.state}/>);
    return (
      <div className="formMyDialogLarge" style={{zIndex:'1000', }}>
        <div className="dialogHeader">
          <p className="dialogTitle1">タイル変換</p>
          <p className="dialogTitle2">{this.state.subTitle}</p>
          <img width="14" height="14" src={iconBatsu} 
               style={{position:'absolute', cursor:'pointer', top:'8px', right:'8px'}} alt="閉じる" data-index="1" 
               onClick={this.onClickClose.bind(this)}/>
        </div>
        
        <div className="dialogContent" style={{display:'block'}}>

          <div className="dialogToolbar">
            <div className="mgmtToolbarItem" data-index="1" onClick={this.onClickIcon.bind(this)}>
              <span className="glyphicon glyphicon-play" aria-hidden="true" style={{fontSize:'20px'}}></span>
              <span>変換実行</span>
            </div>
            <div className="mgmtToolbarItem" data-index="2" onClick={this.onClickIcon.bind(this)}>
              <img width="20" height="20" src={iconUpdate} alt="更新"/>
              <span>更新</span>
            </div>
            {/* 未実装 */}
            <div className="mgmtToolbarItem" style={{marginLeft:'20px'},{display:'none'}} data-index="3" onClick={this.onClickIcon.bind(this)}>
              <img width="20" height="20" src={iconProperty} alt="プロパティ"/>
              <span>プロパティ</span>
            </div>
          </div>

          <Content />
        </div>
      </div>
    );
  }
}

export default GroupMgmt;
