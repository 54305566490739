import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import iconBatsu from './images/white_batsu.svg';

class GroupSetting extends Component {
  constructor(props, context) {
    super(props, context);

    // Type: 0 ... Point Clouds
    //       1 ... オルソ画像
    //       2 ... DEM
    //       3 ... Shapefile
    this.state = {
      type: this.props.type,
      subTitle: this.props.subTitle,
      groups: this.props.groups,
      selectedIndex: -1
    };

    this.parent = this.props.parent;
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    console.log("+++ e.target.value = " + e.target.value);
    let index = Number(e.target.value);
    this.setState({selectedIndex:index});
  }

  onClickClose = () => {
    // 閉じる
    this.parent.hideDialog(2);
  }

  onClickSave = () => {
    // 保存
  }

  onGroupMgmt = () => {
    // グループ管理
    this.parent.dispDialog(4);
  }

  render() {
    let str = this.state.selectedIndex.toString();
    return (
      <div className="formMyDialog">
        <div className="dialogHeader">
          <p className="dialogTitle1">グループ設定</p>
          <p className="dialogTitle2">{this.state.subTitle}</p>
          <img width="14" height="14" src={iconBatsu} 
               style={{position:'absolute', cursor:'pointer', top:'8px', right:'8px'}} alt="閉じる" data-index="1" 
               onClick={this.onClickClose.bind(this)}/>
        </div>
        
        <div className="dialogContent" style={{display:'block'}}>

          <div className="dialogToolbar">
            <div className="dialogToolbarItem" data-index="2" onClick={this.onClickSave.bind(this)}>
              <span className="glyphicon glyphicon-floppy-disk" aria-hidden="true" style={{display:'inline-block', fontSize:'20px'}}></span>
              <span> 保存 </span>
            </div>
          </div>

          <div style={{marginTop:'32px',width:'100%'}}>
            <FormGroup controlId="formControlsSelect">
              <ControlLabel>
                <span className="glyphicon glyphicon-asterisk" aria-hidden="true" style={{display:'inline-block', fontSize:'16px', color:'red'}}></span>
                <span style={{display:'inline-block', marginLeft:'4px'}}>グループ</span>
              </ControlLabel>
              <FormControl componentClass="select" style={{width:'100%'}} onChange={this.onChange} placeholder="選択してください" value={str}>
                <option value="-1">（グループ無し）</option>
                <option value="0">GROUP1</option>
                <option value="1">GROUP2</option>
                <option value="2">GROUP3</option>
                <option value="3">GROUP4</option>
                <option value="4">GROUP5</option>
              </FormControl>
            </FormGroup>
          </div>
          <div style={{marginTop:'16px',width:'100%'}}>
            <div onClick={this.onGroupMgmt} style={{textAlign:'right', fontSize:'18px', color:'blue', cursor:'pointer'}}>グループ管理</div>
          </div>
        </div>
      </div>
    );
  }
}

export default GroupSetting;
