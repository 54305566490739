import React, { Component } from 'react';
import {Modal, Button} from 'react-bootstrap';

class ProfileDialog extends Component {
    constructor(props){
      super(props);
      this.parent = this.props.parent;
      this.state = {
        visible: true
      };
      this.loginInfo = this.props.loginInfo;
      this.userInfo = this.loginInfo.User;
      this.onClickClose = this.onClickClose.bind(this);
    }

    onClickClose() {
        this.setState({visible:false});
    }

    renderItems() {
      let userInfo = this.userInfo;
      let list = userInfo.SmpfGroups;
      let index = -1;
      return list.map((item) => {
        index += 1;
        return (
          <li key={"org"+index.toString()}>ID：{item.SmpfGroup.SmpfGroupId}，
            <span style={{marginLeft:'8px'}}>{item.SmpfGroup.SmpfGroupName}</span></li>
        );
      });
    }

    render() {
        return (
            <div>
              <Modal show={this.state.visible} onHide={this.onClickClose}>

                <Modal.Header closeButton>
                <Modal.Title><span className="glyphicon glyphicon-list-alt my_menu_icon" aria-hidden="true">&nbsp;プロフィール</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table style={{fontSize:'18px'}}>
                        <tbody>
                            <tr style={{padding:'4px'}}>
                                <td style={{padding:'8px'}}>ユーザーID</td>
                                <td style={{padding:'8px'}}>{this.userInfo.Id}</td>
                            </tr>
                            <tr style={{padding:'4px'}}>
                                <td style={{padding:'8px'}}>ログイン名</td>
                                <td style={{padding:'8px'}}>{this.userInfo.UserName}</td>
                            </tr>
                            <tr style={{padding:'4px'}}>
                                <td style={{padding:'8px'}}>表示名</td>
                                <td style={{padding:'8px'}}>{this.userInfo.DisplayName}</td>
                            </tr>
                            <tr style={{padding:'4px'}}>
                                <td style={{padding:'8px', verticalAlign:'top'}}>所属組織</td>
                                <td>
                                    <ul style={{listStyle:'none', padding:'4px 8px'}}>
                                        {this.renderItems()}
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onClickClose}>閉じる</Button>
                </Modal.Footer>
              </Modal>
            </div>
        );        
    }
}  

export default ProfileDialog;
