import React, { Component } from 'react';
import icon05 from './images/05.png';

class MyMenu extends Component {
  constructor(props, context){
    super(props, context);
    this.parent = this.props.parent;
    this.myApp = this.props.myApp;
    this.state = {
      orgIndex: this.props.orgIndex
    };

    this.onClickMenu = this.onClickMenu.bind(this);
    this.onLogout = this.props.onLogout;
  }

  //--------------------------------------------------------------------
  // 組織選択の処理
  // 
  onClickOrgMenu(event) {
    let str = event.currentTarget.getAttribute('data-index');
    let index = Number(str);
    if (index === this.state.orgIndex) {
      return;
    }
    this.props.onSelectOrgH02(index);
    this.setState({orgIndex:index});
  }

  //--------------------------------------------------------------------
  // ポップアップメニューの処理
  // 
  onClickMenu(event) {
    let str = event.currentTarget.getAttribute('data-index');
    let index = Number(str);
    if (index === 0) {
      // ログアウト
      this.onLogout();
    }
    else if (index === 1) {
      // プロフィール
      this.parent.setState({popupMenuVisible: false, changePasswordVisible: false, profileDlgVisible: true});
    }
    // else if (index === 2) {
    //   // パスワード変更
    //   this.parent.setState({popupMenuVisible: false, changePasswordVisible: true, profileDlgVisible: false});
    // }
  }

  //--- 組織一覧の描画 -------------------------------------------------
  renderItems() {
    if (!this.props.loginInfo) {
      return;
    }
    let userInfo = this.props.loginInfo.User;
    // let orgInfo = userInfo.SmpfGroups[this.props.orgIndex];
    let list = userInfo.SmpfGroups;  // this.props.userInfo;
    let index = -1;
    return list.map((item) => {
      index += 1;
      return (
        <div key={"org"+index.toString()} 
          className={(index === this.state.orgIndex) ? 'my_org_item select':'my_org_item'} 
          onClick={this.onClickOrgMenu.bind(this)}
          data-index={""+index}>{item.SmpfGroup.SmpfGroupName}</div>
      );
    });
  }

  render() {
    return (
      <div id="menu" className="my_menu" style={{textAlign:'left', zIndex:'1000'}}>
        <div className="my_menu_item" data-index="0" onClick={this.onClickMenu}>
          <span className="glyphicon glyphicon-log-out my_menu_icon" aria-hidden="true">&nbsp;ログアウト</span>
        </div>
        <div className="my_line"></div>
        <div className="my_menu_item" data-index="1" onClick={this.onClickMenu}>
          <span className="glyphicon glyphicon-list-alt my_menu_icon" aria-hidden="true">&nbsp;プロフィール</span>
        </div>
        <div className="my_line"></div>
        {/* <div className="my_menu_item" data-index="2" onClick={this.onClickMenu}>
          <img src={icon05} width="20" height="20" style={{marginRight:'12px'}} alt="パスワード変更"/>&nbsp;パスワード変更
        </div>
        <div className="my_line" hidden></div> */}
        <div className="my_org">
          <div className="my_org_title">組織</div>
          {/*
          <div className="my_org_item select" data-index="0">朝日航洋株式会社</div>
          <div className="my_org_item" data-index="1">株式会社ＡＢＣ</div>
          */}
          {this.renderItems()}
        </div>
      </div>
    );
  }
}

export default MyMenu;
