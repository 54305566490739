import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Head02 from './head02';
import Footer from './footer';
import icon06 from './images/06.png';

//--- データセット一覧 ------------------------------------------------
class ProjectMenu extends Component {

  constructor(props, context){
    super(props, context);

    this.state = {
      fCommExec: false
    };
    
    this.items = [
      {title: "3D View", config: true, link:'/portal/1', enable: false},
      {title: "2D View", config: true, link:'/portal/1', enable: false},
      {title: "データ管理", config: false},
      {title: "データマネジメント（AACデータ管理）", config: false}
    ];

    this.orgIndex = this.props.orgIndex;
    this.myApp = this.props.myApp;
    this.projInfo = this.myApp.curProjectInfo;
    this.loginInfo = this.myApp.loginInfo;
    //HACK：json取得
    let config = this.projInfo.configJson;
    if (config && config.POTREE) {
      if (config.POTREE.pointclouds && config.POTREE.pointclouds.length > 0) {
        this.items[0].enable = true;
      }
    }
    if (config && config.ORTHOPHOTO) {
      let flagUrl = (typeof config.ORTHOPHOTO.url !== "undefined");
      if (config.ORTHOPHOTO.photos && config.ORTHOPHOTO.photos.length > 0) {

      // "folder", "bbox", "minResolution", "maxResolution", "ext"
      // "projection", "projectionName"
      // "tms", "sourceDir", "sourceName"

        let flag2d = false;
        for (let i = 0; i < config.ORTHOPHOTO.photos.length; i++) {
          let flag = true;
          let info = config.ORTHOPHOTO.photos[i];
          if (!flagUrl) {
            flag = (typeof info.url !== "undefined");
          }
          flag &= (typeof info.folder !== "undefined");
          flag &= (typeof info.bbox !== "undefined");
          flag &= (typeof info.minResolution !== "undefined");
          flag &= (typeof info.maxResolution !== "undefined");
          flag &= (typeof info.ext !== "undefined");
          flag &= (typeof info.tms !== "undefined");
          flag &= (typeof info.projection !== "undefined");
          flag &= (typeof info.projectionName !== "undefined");
          flag &= (typeof info.sourceDir !== "undefined");
          flag &= (typeof info.sourceName !== "undefined");
          flag2d |= flag;
        }
        this.items[1].enable = flag2d;
      }
    }    

    console.log(this.myApp.debugFlag);
  }
  //-----------------------------------------------------------
  componentDidMount() {
    document.documentElement.lang = "ja"; 
  }
  //--- ログアウト --------------------------------------------
  logout() {
    this.props.myApp.logout();
    this.props.history.push('/');
  }

  //--- ポータル画面へ移動 ------------------------------------
  toPortal() {
    this.props.history.push('/portal/' + this.props.serviceId);
  }

  //--- プロジェクト一覧画面へ移動 ------------------------------------
  toDataSetList() {
    this.props.history.push("/dataSetList");
  }

  //--- ポイントクラウド --------------------------------------
  loadPointCloudInfoForDebug() {
    let url = "http://localhost/dev3/sample_list.json";
    let self = this;
    this.setState({fCommExec: true});
    fetch(url, {
        method: 'GET',
        cache: 'no-cache'
    }).then(function(response) {
        self.setState({fCommExec: false});
        if (response.ok) {
          return response.json();
        }
        throw new Error('Point Cloud情報を取得できませんでした.');
    }).then(function(json) { 
        self.myApp.pointCloudInfo = json.FileInfo;
        self.props.history.push('/dataSetMgmt');
    }).catch((err) => {
        alert(err);
        self.setState({fCommExec: false});
    });
  }

  //--- ポイントクラウド --------------------------------------
  loadPointCloudInfo() {
    if (this.myApp.debugFlag) {
      this.loadPointCloudInfoForDebug();
      return;
    }
    let info = this.loginInfo;
    let pid = this.projInfo.ProjectId;
    let param = 'Bearer ' + info.token;
    let url = this.myApp.fileApiUrl + "/sourcefileList?datatype=Las&projectid=" + pid;
    console.log(url);

    let self = this;
    this.setState({fCommExec: true});

    fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        headers: { 'Authorization': param }

    }).then(function(response) {
        self.setState({fCommExec: false});
        if (response.ok) {
          return response.json();
        }
        throw new Error('Point Cloud情報を取得できませんでした.');

    }).then(function(json) { 
        self.myApp.pointCloudInfo = json.FileInfo;
        self.props.history.push('/dataSetMgmt');

    }).catch((err) => {
        alert(err);
        self.setState({fCommExec: false});
    });
  }

  //--- メニューの選択 ----------------------------------------
  onSelectMenu(event) {
    let str = event.currentTarget.getAttribute('data-index');
    console.log("+++ data-index = " + str);
    let index = Number(str);
    let config = this.projInfo.configJson;
    if (index === 0) {
      // 3D Viewer
      if (config && config.POTREE) {
        if (config.POTREE.pointclouds && config.POTREE.pointclouds.length > 0) {
          this.props.history.push('/viewer3d');
        }
      }
    }
    else if (index === 1) {
      // 2D Viewer
      if (config && config.ORTHOPHOTO) {
        if (config.ORTHOPHOTO.photos && config.ORTHOPHOTO.photos.length > 0) {
          this.props.history.push('/viewer2d');
        }
      }
    }
    else if (index === 2) {
      // データ管理
      // this.props.history.push('/dataSetMgmt');
      this.loadPointCloudInfo();
    }
    else if (index === 3) {
      // AAC データ管理
    }
  }

  //--- 一覧の項目を描画 --------------------------------------
  renderItems() {
    let list = this.items;
    let index = -1;
    return list.map((item) => {
      index += 1;
      if (item.config) {
        let str = 'my_list_item_title';
        if (item.enable === false) {
          str = 'my_list_item_title_disabled';
        }        
        return (
          <div key={index.toString()} className="my_list_line">
            <div className="my_list_item" >
              <span className={str} data-index={""+index} style={{display:'inline-block', width:'560px'}} onClick={this.onSelectMenu.bind(this)}>{item.title}</span>
              {/* <a href={item.link} target="_blank" rel="noopener noreferrer">
                <img className="my_list_item_icon" width="32" height="32" src={icon06} alt="設定"/>
              </a> */}
            </div>
            <div className="my_line"></div>
          </div>
        );
      }
      else {
        return (
          <div key={index.toString()} className="my_list_line">
            <div className="my_list_item" >
              <span className="my_list_item_title" data-index={""+index} style={{display:'inline-block', width:'600px', marginTop:'4px'}} onClick={this.onSelectMenu.bind(this)}>{item.title}</span>
            </div>
            <div className="my_line"></div>
          </div>
        );
      }
    });
}

  //--- 描画 --------------------------------------------------
  render() {
    let spinnerStyle = {display:'none'};
    if (this.state.fCommExec) {
      spinnerStyle = {display:'block', zIndex:'100'};
    }
    return (
      <div className="App">

        <Head02 onLogout={this.logout.bind(this)} toPortal={this.toDataSetList.bind(this)} {...this.props}/>
        <div className="divideline clearfix"></div>

        <div className="contents_center" style={{margin:'auto'}}>
          <div className="project-menu">
            <div className="project-menu-header">
              <span className="glyphicon glyphicon-list-alt" aria-hidden="true"></span>&nbsp;<span>{this.props.projInfo.ProjectName}</span>
            </div>
            {this.renderItems()}

          </div>

          <div className="spinner-loader" style={spinnerStyle}></div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(ProjectMenu);

