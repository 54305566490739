import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button } from 'react-bootstrap';
import Head01 from './head01';
import Footer from './footer';
import * as msal from 'msal';
import { loginRequest, msalConfig } from './authConfig';

class FormLogin extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      msalClient: msal.UserAgentApplication,
      userName: "",
      passWord: "",
      fCommExec: false,
    };
    this.myApp = this.props.myApp;
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangePassWord = this.onChangePassWord.bind(this);

    this.onClickLogon = this.onClickLogon.bind(this);
    this.onPressEnter = this.onPressEnter.bind(this);
  }

  //-----------------------------------------------------------
  componentDidMount() {
    document.documentElement.lang = "ja";
    document.addEventListener("keyup", this.onPressEnter, false);
    this.myApp.clearCookieTokenInfo();
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onPressEnter);
  }

  onPressEnter(event) {
    if (event.keyCode === 0x0d) {
      var self = this;
      setTimeout(function() {
        self.onClickLogon(event);
      }, 0);
    }
  }

  onChangeUserName(event) {
    this.setState({ userName: event.target.value });
  }

  onChangePassWord(event) {
    this.setState({ passWord: event.target.value });
  }

  async onClickLogon(event) {
    this.msalClient = new msal.UserAgentApplication(msalConfig);
    this.msalClient
      .loginPopup(loginRequest)
      .then((loginResponse) => {
        this.msalClient.acquireTokenSilent(loginRequest).then((token) => {
          const ac = loginResponse.account;
          console.log("ログイン成功");
          console.log(ac.userName); //このメールアドレスでloginと同じ情報を取得するように修正
          // ローカルストレージにaccessTokenを保存
          console.log(token.accessToken);
          localStorage.setItem("accessToken", token.accessToken);
          let postData = JSON.stringify({
            // username: this.state.userName,
            // password: this.state.passWord,
            username: loginResponse.account.userName
          });
          let url = this.myApp.apiUrl + "/loginInfo";
          let headers = { "Content-Type": "application/json" };
          let method = "POST";
  
          let self = this;
          this.setState({ fCommExec: true });
  
          fetch(url, {
            method: method,
            headers: headers,
            body: postData,
          })
            .then(function(response) {
              if (response.ok) {
                return response.json();
              }
              self.setState({ fCommExec: false });
              throw new Error("Network response was not ok.");
            })
            .then(function(json) {
              // self.userNameInput.value = "";
              // self.passWordInput.value = "";
  
              json.User.passWord = self.state.passWord;
  
              self.setState({ userName: "" });
              self.setState({ passWord: "" });
  
              console.log(json);
              self.props.onLogin(json);
              //self.props.history.push("/portal/1");
              //self.props.history.push('/dataSetList');
          
              //HACK:storage
              self.getProjectList(json);
              // self.getStorageList(json);
            })
            .catch((err) => {
              alert(
                // "ログイン認証に失敗しました。\nユーザーIDとパスワードをご確認のうえ再度ログインしてください。"
                "ログイン認証に失敗しました。\nログインユーザーをご確認のうえ再度ログインしてください。"
              );
              console.log(err);
              self.setState({ fCommExec: false });
            });
        })
        .catch((error) => {
          console.log("ログイン失敗\r\n" + error);
        });
      })  
  }

  //-----------------------------------------------------------------------------
  getProjectList(info) {
    let param = "Bearer " + info.token;
    let headers = { Authorization: param };
    let url =
      this.myApp.apiUrl +
      "/project/projectlist?groupid=" +
      info.User.SmpfGroups[0].SmpfGroup.SmpfGroupId;

    let debugFlag = false;
    if (this.myApp.debugFlag) {
      url = "http://localhost/dev3/projectList.json";
      headers = {};
      debugFlag = true;
    }

    let self = this;

    fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        self.setState({ fCommExec: false });

        if (!response.ok) {
          let status = response.status;
          console.log("+++ ERROR: " + status);
          return response.json().then((err) => {
            throw Error(err);
          });
        }
        return response.json();
      })
      .then((json) => {
        console.log("+++ Response: " + JSON.stringify(json));
        for (let i = 0; i < json.SmpfProjects.length; i++) {
          json.SmpfProjects[i].container = "";
          let wi = json.SmpfProjects[i].ProjectUrl.lastIndexOf("/");
          if (wi >= 0) {
            json.SmpfProjects[i].container = json.SmpfProjects[
              i
            ].ProjectUrl.substr(wi + 1);
            console.log(json.SmpfProjects[i].container);
          }
          //---2020/03/25 ---
          // json.SmpfProjects[i].config = json.SmpfProjects[i].ProjectUrl + "/config.json";

          //HACK:クライアントにストレージ情報は返さない
          // //--- 2018/12/10 ---
          // json.SmpfProjects[i].storage = self.myApp.getStorageInfoFromUrl(json.SmpfProjects[i].ProjectUrl);

          //---2020/03/25 ---
          let prjUrl = json.SmpfProjects[i].ProjectUrl;
          let swi = 8;
          if (prjUrl.indexOf("https://") === 0) {
          } else if (prjUrl.indexOf("http://") === 0) {
            swi = 7;
          } else {
            throw new Error("not (http:// or https://)");
          }

          let str = prjUrl.substr(swi);
          swi = str.indexOf(".");
          json.SmpfProjects[i].storage = { storageName: "", containerName: "" };
          if (swi >= 0)
            json.SmpfProjects[i].storage.storageName = str.substr(0, swi);

          swi = str.lastIndexOf("/");
          if (swi >= 0)
            json.SmpfProjects[i].storage.containerName = str.substr(swi + 1);

          if (debugFlag) {
            json.SmpfProjects[i].config = this.myApp.debugConfig[i];
          }
        }

        info.User.SmpfGroups[0].SmpfProject = json.SmpfProjects;

        self.props.onLogin(info);
        //self.props.history.push("/portal/1");
        self.props.history.push("/dataSetList");
      })
      .catch((err) => {
        console.log(err);
        alert(
          "プロジェクト一覧を取得できませんでした。。\nシステム管理者にお問い合わせください。"
        );
      });
  }

  //---2020/03/25---
  // //-----------------------------------------------------------------------------
  // getStorageList(info) {

  //   let param = 'Bearer ' + info.token;
  //   let headers = {'Authorization': param};
  //   let url = this.myApp.apiUrl + "/master/storagelist";

  //   if (this.myApp.debugFlag) {
  //     url = "http://localhost/dev3/storagelist.json";
  //     headers = {};
  //   }

  //   let self = this;

  //   fetch(
  //     url,
  //     {
  //       method: 'GET',
  //       headers: headers
  //     }
  //   ).then(response => {

  //     if(!response.ok) {
  //       let status = response.status;
  //       console.log("+++ ERROR: " + status);
  //       return response.json().then((err) => {
  //         throw Error(err);
  //       });
  //     }
  //     return response.json();

  //   }).then(json => {

  //     self.myApp.setStoragesList(json.storages);

  //     self.getProjectList(info);

  //   }).catch((err) => {

  //     console.log(err);
  //     alert("ストレージ情報を取得できませんでした。。\nシステム管理者にお問い合わせください。");
  //   });
  // }

  //-----------------------------------------------------------------------------
  render() {
    let colStyle = { color: "black" };
    let btnStyle = { background: "linear-gradient(#3177b1, #275d8c)" };
    let btnStyle2 = { marginLeft: "4px" };
    let divStyle = {
      bottom: "0",
      width: "100%",
      padding: "12px",
      marginTop: "20px",
      background: "#eee",
    };
    let spinnerStyle = { display: "none" };
    if (this.state.fCommExec) {
      spinnerStyle = { display: "block", zIndex: "100" };
    }

    //--- 2019/04/18 ---
    if (this.myApp.restoreUserInfo()) {
      var self = this;
      setTimeout(function() {
        //self.props.history.push("/portal/1");
        self.props.history.push("/dataSetList");
      }, 0);
      return <div className="App" />;
    }

    return (
      <div className="App">
        <Head01 />
        <div className="divideline clearfix" />

        <div className="contents_center">
          <div className="form-signin">
            <div className="form-signin-label">
              <span className="glyphicon glyphicon-user" aria-hidden="true" />
              &nbsp;
              <span>ログイン</span>
            </div>
            <center>
              {/* <table className="form-signin-table"><tbody>
                    <tr>
                      <td><span style={colStyle}>ログイン名</span></td>
                      <td><input type="text" className="form-control" name="Username" placeholder="ログイン名" required="" onChange={this.onChangeUserName}
                                 value={this.state.userName} ref={input => {this.userNameInput = input}}/></td>
                    </tr>
                    <tr>
                      <td><span style={colStyle}>パスワード</span></td>
                      <td><input type="password" className="form-control" name="Password" placeholder="パスワード" required=""  onChange={this.onChangePassWord}
                                 value={this.state.passWord} ref={input => {this.passWordInput = input}}/></td>
                    </tr>
                  </tbody></table> */}
            </center>

            <div style={divStyle}>
              <Button
                className="btn btn-lg btn-primary btn-block"
                style={btnStyle}
                onClick={this.onClickLogon}
              >
                ログイン
                <span
                  className="glyphicon glyphicon-log-in"
                  aria-hidden="true"
                  style={btnStyle2}
                />
              </Button>
            </div>

            <div className="spinner-loader" style={spinnerStyle} />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(FormLogin);
