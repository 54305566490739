import React, { Component } from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import './css/App.css';
import FormLogin from './loginForm';
import PagePortal from './portalPage';
import DataSetList from './dataSetList';
import ProjectMenu from './projectMenu';
import Viewer3d from './viewer3d';
import Viewer2d from './viewer2d';
import DataSetMgmt from './dataSetMgmt';
import Blowfish from 'egoroof-blowfish';

class App extends Component {
 
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props, context){

    super(props, context);

    this.state = {
      userInfo: {}
    };

    this.onLogin = this.onLogin.bind(this);
    this.onSelectService = this.onSelectService.bind(this);
    this.onSelectProject = this.onSelectProject.bind(this);
    this.onSelectApplication = this.onSelectApplication.bind(this);    
  
    this.loginInfo = null;
    this.curOrgIndex = 0;
    this.curServiceId = 0;
    this.curProjectInfo = {};

    this.storageInfo = {};

    this.fileList = {}; //--- 2018/12/04 ---

    this.debugFlag = false; //--- 注意：リリースの際には false にすること。

    this.apiUrl = "https://smpfapi.azurewebsites.net/api";
    //this.apiUrl = "https://prod-smpf-webapp.azurewebsites.net/api";
    //this.serviceApiUrl = "https://3dviewer-webapp.azurewebsites.net/test/service/api";
    //this.serviceApiUrl = "http://www.smpf.cloud/service/api";
    this.serviceApiUrl = "https://prod-3dviewer.azurewebsites.net/service/api";    //this.serviceApiUrl = "http://localhost:14595/api";
    this.fileApiUrl = this.serviceApiUrl + "/file";  
    //this.fileApiUrl = "https://3dviewer-webapp.azurewebsites.net/service/api/file";
    //this.proxyUrl = "https://proxy.smpf.cloud"; //--- 2020/03/30 ---
    this.proxyUrl = "https://prod-smpfdiistribution.japaneast.cloudapp.azure.com"; //--- 2020/03/30 ---
    if (!this.debugFlag) {
      //--- For Release ---
      //this.viewerUrl = "https://3dviewer-webapp.azurewebsites.net/test/viewer/";
      //this.viewerUrl = "https://www.smpf.cloud/viewer/";
      //this.viewerUrl = "http://localhost:3000/viewer/";
      this.viewerUrl = "https://prod-3dviewer.azurewebsites.net/viewer/";
      this.baseName = "";  //※.リリースビルドを行う際に、適切なパスを設定する。
      //this.baseName = "/test";  //※.リリースビルドを行う際に、適切なパスを設定する。

      //--- 水中点検ロボ ---
      this.afloUrl = "http://goingsample.japanwest.cloudapp.azure.com/aflo/";
      // this.afloUrl = "http://localhost:10473/";
      this.loginUrl = "http://goingsample.japanwest.cloudapp.azure.com/works/";

    } else {

    //--- For Debug ---
      this.viewerUrl = "http://localhost/dev3/viewer/";
      this.baseName = "/";

      // this.baseName = "/works";  // --> IIS用 

      // 水中点検ロボ
      // this.afloUrl = "http://localhost:8888/aflo/";
      this.afloUrl = "http://localhost:10473/";

      //--- 2019/01/30 ---
      this.debugConfig = [
        "http://localhost/dev/works/ooizumi/config.json",       // 大泉ジャンクション
        "http://localhost/dev/works/haruki/config.json",        // 春木川
        "http://localhost/dev/works/hiroshima/config.json",     // 広島
        "http://localhost/dev/works/maruyama/config2.json",     // 円山川
        // "http://localhost/dev/works/shinano/config.json"        // 信濃川
        "http://localhost/dev/works/agano/config.json"          // 阿賀野川
      ];
    }

    this.viewer3dUrl = this.viewerUrl + "pointviewer.html";
    this.viewer2dUrl = this.viewerUrl + "orthoviewer.html";
  }

  getWindowSize() {
    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    ww = w.innerWidth || e.clientWidth || g.clientWidth,
    wh = w.innerHeight|| e.clientHeight|| g.clientHeight;
    return {w:ww, h:wh};
  }

  componentDidMount() {
    let ret = this.getWindowSize();
    console.log("w:" + ret.w + ", h:" + ret.h);
  }

  logout() {
    // console.log("+++ logoug on App.js +++");
    const { cookies } = this.props;
    cookies.remove('aac_project_info');
    // sessionStorage.removeItem("aac_login_user_info");

    this.userInfo = null;
    this.loginInfo = null;
    this.curOrgIndex = 0;
    this.curServiceId = 0;
    this.curProjectInfo = {};
    this.pointCloudInfo = {};
  }

  onLogin(info) {
    // とりあえず、コピーしておきます。
    // this.userInfo = info;
    this.loginInfo = info;
  }

  onSelectService(id) {
    // ポータル画面に戻るときに、必要になります。
    this.curServiceId = id;
    console.log(id);
  }

  onSelectOrg(index) {
    // 組織が選択された
    this.curOrgIndex = index;
  }

  onSelectProject(info) {
    // 選択されたプロジェクトの情報
    this.curProjectInfo = info;
    this.fileList = {};

    let userInfo = {};
    userInfo.UserName = this.loginInfo.User.UserName;

    info.userInfo = null;
    info.userInfo = userInfo;  //--- 2018/12/07 ---

    info.token = this.loginInfo.token;  //--- 2019/01/11 ---

    info.proxyUrl = this.proxyUrl; //--- 2020/03/30 ---
    //---2020.04.21---
    //次の処理でセットするように変更
    // const { cookies } = this.props;
    // let str = JSON.stringify(info);
    // cookies.set('aac_project_info', str, { path: '/' });    
    // this.setState({str});
  }

  onSelectApplication(info) {
    //storagekeyのクッキー登録    
    const { cookies } = this.props;
    let str = JSON.stringify(info);
    cookies.set('aac_project_info', str, { path: '/' });
    this.setState({str});
  }

  render() {
    //--- ここに <BrowserRouter> を設定する。---
    return (
      <BrowserRouter basename={this.baseName}>
        {/* BrowserRouter直下に置けるコンポーネントは1つだけ */}
        <div>
          <Switch>
            {/* ログイン画面 */}
            <Route exact path="/"
                  render={props => <FormLogin onLogin={userinfo => this.onLogin(userinfo)} myApp={this} {...props} />}
            />
            <Route path="/login"
                  render={props => <FormLogin onLogin={userinfo => this.onLogin(userinfo)} myApp={this} {...props} />}
            />
            {/* ポータル画面 */}
            <Route path="/portal/:serviceId" 
                  render={props => <PagePortal loginInfo={this.loginInfo} orgIndex={this.curOrgIndex} serviceId={this.curServiceId}
                                               onSelectService={id => this.onSelectService(id)} 
                                               onSelectOrg={index => this.onSelectOrg(index)} myApp={this} {...props} />}
            />
            {/* データセット一覧画面 */}
            <Route path="/dataSetList" 
                  render={props => <DataSetList loginInfo={this.loginInfo} orgIndex={this.curOrgIndex} serviceId={this.curServiceId} 
                                                onSelectProject={info => this.onSelectProject(info)}                                                 
                                                onSelectApplication={info => this.onSelectApplication(info)} 
                                                onSelectOrg={index => this.onSelectOrg(index)} myApp={this} {...props} />}
            />
            {/* データセット・メニュー画面 */}
            <Route path="/projectMenu" 
                  render={props => <ProjectMenu loginInfo={this.loginInfo} orgIndex={this.curOrgIndex} serviceId={this.curServiceId} 
                                                projInfo={this.curProjectInfo} myApp={this}
                                                onSelectOrg={index => this.onSelectOrg(index)} {...props} />}
            />

            {/* 3D Viewer 画面 */}
            <Route path="/viewer3d" 
                  render={props => <Viewer3d loginInfo={this.loginInfo} orgIndex={this.curOrgIndex} serviceId={this.curServiceId} 
                                             projInfo={this.curProjectInfo} myApp={this} {...props} />}
            />
            {/* 2D Viewer 画面 */}
            <Route path="/viewer2d" 
                  render={props => <Viewer2d loginInfo={this.loginInfo} orgIndex={this.curOrgIndex} serviceId={this.curServiceId} 
                                             projInfo={this.curProjectInfo} myApp={this} {...props} />}
            />
            {/* データ管理画面 */}
            <Route path="/dataSetMgmt" 
                  render={props => <DataSetMgmt loginInfo={this.loginInfo} orgIndex={this.curOrgIndex} serviceId={this.curServiceId} 
                                                projInfo={this.curProjectInfo} myApp={this} {...props} />}
            />
            {/* AACデータ管理画面 */}

          </Switch>
        </div>
      </BrowserRouter>
    );
  }

  //-------------------------------------------------------------------
  // プロジェクト一覧の取得
  //
  getProjectList(index, cb) {

    let info = this.loginInfo;
    let gid = info.User.SmpfGroups[index].SmpfGroup.SmpfGroupId;
    let url = this.apiUrl + "/project/projectlist?groupid=" + gid;
    let param = 'Bearer ' + info.token;
    let self = this;

    let headers = null;
    if (this.debugFlag) {
      url = "http://localhost/dev3/projectList"+ index +".json";
      headers = {};
    }
    else {
      headers = { 'Authorization': param };
    }

    if (self.loginInfo.User.SmpfGroups[index].SmpfProject !== undefined && 
        self.loginInfo.User.SmpfGroups[index].SmpfProject !== null) {
      //--- 読み込み済みの場合。---
      setTimeout(function() {
        cb();
      }, 100);
    }

    fetch(
      url,
      {
        method: 'GET',
        headers: headers
      }
    ).then(response => {
      self.setState({fCommExec: false});

      if(!response.ok) {
        let status = response.status;
        console.log("+++ ERROR: " + status);
        return response.json().then((err) => {
          throw Error(err);
        });
      }
      return response.json();

    }).then(json => {
      
      console.log("+++ Response: " + JSON.stringify(json));
      for (let i = 0; i < json.SmpfProjects.length; i++) {
        json.SmpfProjects[i].container = "";
        let wi = json.SmpfProjects[i].ProjectUrl.lastIndexOf('/');
        if (wi >= 0) {
          json.SmpfProjects[i].container = json.SmpfProjects[i].ProjectUrl.substr(wi+1);
          console.log(json.SmpfProjects[i].container);
        }

        //--- 2020/03/25 --- 
        //configurlはAPIから取得に変更
        //json.SmpfProjects[i].config = json.SmpfProjects[i].ProjectUrl + "/config.json";

        //HACK:ストレージ情報はできる限りクライアントに返さない
        //--- 2018/12/10 ---
        // json.SmpfProjects[i].storage = self.getStorageInfoFromUrl(json.SmpfProjects[i].ProjectUrl);
        
        //--- 2020/03/25 ---         
        // "ProjectUrl":"https://smpf101org1.blob.core.windows.net/1-3dview"
        let prjUrl = json.SmpfProjects[i].ProjectUrl;
        let swi = 8;
        if (prjUrl.indexOf("https://") === 0) {

        } else if (prjUrl.indexOf("http://") === 0) {
          swi = 7;
        } else {
          throw new Error("not (http:// or https://)");          
        }

        let str = prjUrl.substr(swi);
        swi = str.indexOf(".");
        json.SmpfProjects[i].storage = {"storageName":"","containerName":""};
        if (swi >= 0)
        json.SmpfProjects[i].storage.storageName = str.substr(0, swi);
        
        swi = str.lastIndexOf("/");
        if (swi >= 0)
        json.SmpfProjects[i].storage.containerName = str.substr(swi + 1);
        
        //storageKeyを返却しないように変更
        // ret.key = this.storageInfo[ret.storageName].key1;

        if (this.debufFlag) {
          json.SmpfProjects[i].config = this.debugConfig[i];
        }
      }
      self.loginInfo.User.SmpfGroups[index].SmpfProject = json.SmpfProjects;

      cb();
      
    }).catch((err) => {
      
      console.log(err);
      cb();
    });
  }
  //-------------------------------------------------------------------
  // saskeyの取得
  //
  getAccesskey(info,cb){
    let url = this.fileApiUrl + "/accesskey?projectid=" + info.ProjectId;
    let param = 'Bearer ' + info.token;
    let headers = { 'Authorization': param , 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'};
    info.storage["storagekey"] ="";
    fetch(
      url, {
        method: 'GET',
        headers: headers
      }
      ).then(response => {
        if(!response.ok) {
          let status = response.status;
          console.log("+++ ERROR: " + status);
          return response.json().then((err) => {
            throw Error(err);
          });
        }
        return response.json();
      }).then(json => {
        info.storage["storagekey"] = json.accesskey;
        document.cookie = "storagekey=" + json.accesskey;
        cb();      
      }).catch((err) => {
        info.storage["storagekey"] = "";
        console.log(err);
        cb();
      });      
  }

  //-------------------------------------------------------------------
  // config.json の取得
  //configjson（storage構成ファイル）はサイズが大きいのでとりあえずcookie保存はしない。
  //
  getProjectConfig(info, cb) {
    let url = this.fileApiUrl + "/config?projectid=" + info.ProjectId;
    let param = 'Bearer ' + info.token;
    info.configJson = null;
    let headers = { 'Authorization': param , 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'};
    fetch(
      url, {
        method: 'GET',
        headers: headers
      }
    ).then(response => {
      if(!response.ok) {
        let status = response.status;
        console.log("+++ ERROR: " + status);
        return response.json().then((err) => {
          throw Error(err);
        });
      }
      return response.json();

    }).then(json => {
      info.configJson = json;      
      cb();

    }).catch((err) => {
      info.configJson = {};
      console.log(err);
      cb();
    });
  }

  //-------------------------------------------------------------------
  // ファイル一覧の取得
  //
  getFileList(pid, ftype, cb) {

    let info = this.loginInfo;
    let param = 'Bearer ' + info.token;
    let url = this.fileApiUrl + "/sourcefileList?datatype=" + ftype + "&projectid=" + pid;
    console.log(url);

    var headers = null;
    if (this.debugFlag) {
      if (ftype === "Ortho") {
        url = "http://localhost/dev3/fileList_ortho.json";
      } else if (ftype === "Shape") {
        url = "http://localhost/dev3/fileList_shape.json";
      } else if (ftype === "3dModel") {
        url = "http://localhost/dev3/fileList_3dmodel.json";
      } else if (ftype === "Las") {
        url = "http://localhost/dev3/fileList_las.json";
      } else {
        url = "http://localhost/dev3/fileList_etc.json";
      }
      headers = {}
    }
    else {
      headers = { 'Authorization': param };
    }

    let self = this;

    fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        headers: headers

    }).then(function(response) {
        if (response.ok) {
          return response.json();
        }
        throw new Error('ファイル一覧を取得できませんでした.');

    }).then(function(json) { 
        self.fileList[ftype] = json.FileInfo;
        cb(0, json);

    }).catch((err) => {
        cb(-1, null);
        alert(err);
    });
  }
  
  getFolderList(pid, ftype, cb) {

    let info = this.loginInfo;
    let param = 'Bearer ' + info.token;
    let url = this.fileApiUrl + "/folderList?datatype=" + ftype + "&projectid=" + pid;
    console.log(url);

    var headers = null;
    if (this.debugFlag) {
      if (ftype === "Ortho") {
        url = "http://localhost/dev3/fileList_ortho.json";
      } else if (ftype === "Shape") {
        url = "http://localhost/dev3/fileList_shape.json";
      } else if (ftype === "3dModel") {
        url = "http://localhost/dev3/fileList_3dmodel.json";
      } else if (ftype === "Las") {
        url = "http://localhost/dev3/fileList_las.json";
      } else {
        url = "http://localhost/dev3/fileList_etc.json";
      }
      headers = {}
    }
    else {
      headers = { 'Authorization': param };
    }

    let self = this;

    fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        headers: headers

    }).then(function(response) {
        if (response.ok) {
          return response.json();
        }
        throw new Error('フォルダ一覧を取得できませんでした.');

    }).then(function(json) { 
        cb(0, json);

    }).catch((err) => {
        cb(-1, null);
        alert(err);
    });
  }

  //-------------------------------------------------------------------
  // ステータスの取得
  //
  getStatus(pid, gid, process, folders, cb) {
    let info = this.loginInfo;
    let param = 'Bearer ' + info.token;
    let url = this.serviceApiUrl + "/get_batch_status";
    let bodyobj = { "projectid": pid, "groupid": gid, "process": process, "groupnames": folders };
    let headers = { 'Authorization': param , 'Content-Type': 'application/json'};

    let self = this;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyobj),
      cache: 'no-cache',
      headers: headers

    }).then(function(response) {
        if (response.ok) {
          return response.json();
        }
        throw new Error('ステータスを取得できませんでした.');

    }).then(function(json) { 
        cb(0, json);

    }).catch((err) => {
        cb(-1, null);
        alert(err);
    });
  }

  //-------------------------------------------------------------------
  // バッチ処理のリクエスト
  //
  executeConvert(pid, gid, groupname, process, param) {
    let info = this.loginInfo;
    let param_auth = 'Bearer ' + info.token;
    let headers = { 'Authorization': param_auth , 'Content-Type': 'application/json'};
    let url = ''

    var bodyobj = {
      "projectid": pid,
      "groupid": gid,
      "process": process
    }

    //Validation
    if(groupname == null){
      throw new Error('グループ名が空です');
      return; 
    } 
    if(param["epsgcode"] == null){
      throw new Error('EPSGコードが空です');
      return; 
    }

    if (process === "convertToOctree") {
      url = this.serviceApiUrl + "/create_viewer_data";
      bodyobj["input"] = "source/pointclouds/" + groupname + "/"
      bodyobj["output"] = "display/pointclouds/" + groupname + "/"
      bodyobj["epsgcode"] = param["epsgcode"]
    }
    else if(process === "tiffToTile"){
      url = this.serviceApiUrl + "/tiff_to_tile";
      bodyobj["input"] = "source/orthophoto/" + groupname + "/"
      bodyobj["output"] = "display/orthophoto/" + groupname + "/"
      bodyobj["epsgcode"] = param["epsgcode"]
      bodyobj["minlevel"] = param["minlevel"];
      bodyobj["maxlevel"] = param["maxlevel"];          
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyobj),
      cache: 'no-cache',
      headers: headers

    }).then(function(response) {
        if (response.ok) {         
          return ;
        }
        throw new Error('処理を実行できませんでした.');
    })
  }


  //-------------------------------------------------------------------
  // ファイルのダウンロード
  //
  downloadFile(info) {
    // cookieに保存されているstoragekeyを取得
    const cookies = document.cookie;
    const array = cookies.split('; ');
    var storagekey = "";
    array.forEach(function(value){
      const content = value.split('=');
      if(content[0] == "storagekey"){
        var firstEqualInd = value.indexOf('=');
        storagekey = value.substring(firstEqualInd + 1);
        return;
      }
    });

    //TODO：APIでのダウンロードに置き換え
    var a = document.getElementById("dummyLink");
    if (!a) {
        // let dummyLink = $(`<a id ="dummyLink" href="" download="clipping.las" style="display:none;" target="blank"></a>`);
        // $('body').append(dummyLink);

        let dummyLink = document.createElement("a");
        dummyLink.setAttribute("id", "dummyLink");
        dummyLink.setAttribute("style", "display:none;");
        dummyLink.setAttribute("target", "blank");
        document.body.appendChild(dummyLink);
        a = document.getElementById("dummyLink");
    }
    a.href = info.FileUrl + storagekey;
    a.download = info.FileName;
    setTimeout( function() {
      a.click();
    }, 0);
  }

  //-----------------------------------------------------------------------------
  // ProjectURL から、ストレージとコンテナの名前を取り出す。
  //
  // getStorageInfoFromUrl(url) {

  //   // "ProjectUrl":"https://smpf101org1.blob.core.windows.net/1-3dview"

  //   let ret = {storageName:"", containerName:"", key:""};
  //   let wi = 8;
  //   if (url.indexOf("https://") === 0) {

  //   } else if (url.indexOf("http://") === 0) {
  //     wi = 7;
  //   } else {
  //     return ret;
  //   }

  //   let str = url.substr(wi);
  //   wi = str.indexOf(".");
  //   if (wi >= 0)
  //     ret.storageName = str.substr(0, wi);
    
  //   wi = str.lastIndexOf("/");
  //   if (wi >= 0)
  //     ret.containerName = str.substr(wi + 1);
  //   ret.key = this.storageInfo[ret.storageName].key1;
  //   return ret;
  // }

  //-----------------------------------------------------------------------------
  // ストレージ情報を保存する。
  //
  // setStoragesList(list)　{
  //   if (list === undefined) {
  //     return;
  //   }

  //   for (let i = 0; i < list.length; i++) {
  //     let info = {};
  //     info.storageName = list[i].StorageName;
  //     info.id = list[i].Id;

  //     info.key1 = list[i].AccessKey1;  // TODO: ""で置換する。( 2019/01/11 )
  //     info.key2 = list[i].AccessKey2;  // TODO: ""で置換する。( 2019/01/11 )

  //     this.storageInfo[info.storageName] = info;
  //   }
  // }
  //-----------------------------------------------------------------------------
  // 水中ロボットに渡すトークン情報を空にする
  //
  clearCookieTokenInfo() {
    const { cookies } = this.props;
    cookies.set('aac_login_token', "", { path:'/' });
  }

  //-----------------------------------------------------------------------------
  // 水中ロボットに飛ぶ
  //
  moveToUnderwaterRobot() {

    const { cookies } = this.props;
    let str = this.loginInfo.token;
    
    let bf = new Blowfish('AeroAsahi.SmartMaintanancePlatform', Blowfish.MODE.ECB, Blowfish.PADDING.SPACES);
    let encoded = bf.encode(str, Blowfish.TYPE.UINT8_ARRAY);
    //let decoded = bf.decode(encoded);
    //console.log(decoded);

    // 16進文字列に変換
    let str2 = this.bin2hex(encoded);

    cookies.set('aac_login_token', str2, { path:'/', maxAge:3600 });

    let aac_login_token = str2;

    let obj = {};
    obj.loginInfo = this.loginInfo;    
    obj.curOrgIndex    = this.curOrgIndex;
    obj.curServiceId   = this.curServiceId;
    obj.curProjectInfo = this.curProjectInfo;
    obj.pointCloudInfo = this.pointCloudInfo;
    obj.storageInfo =    this.storageInfo;

    str = JSON.stringify(obj);
    encoded = bf.encode(str, Blowfish.TYPE.UINT8_ARRAY);
    str2 = this.bin2hex(encoded);
    localStorage.setItem('aac_login_user_info', str2);
    // cookies.set('aac_login_user_info', str2, { path:'/', maxAge:3600 });

    this.afloLogin(aac_login_token);
  }
  //-----------------------------------------------------------------------------
  // 水中点検ロボに遷移します。
  //
  afloLogin(token) {
    //let postData = JSON.stringify({ 
    //    "userid"  :"", 
    //    "password":token
    //});
    // let headers = {'Content-Type': 'application/json'};

    var self = this;
    let url = this.afloUrl + "DoLogin";
    let method = "POST";

    let group = this.loginInfo.User.SmpfGroups[this.curOrgIndex];

    let obj = {
        "userid"  :this.loginInfo.User.UserName, 
        "groupid"  :group.SmpfGroup.SmpfGroupId, 
        "password":token
    };
    let postData = Object.keys(obj).map((key)=>key+"="+encodeURIComponent(obj[key])).join("&");
    let headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'};

    fetch(url, {
        mode: 'cors',
        credentials: "include",
        method: method,
        headers: headers,
        body: postData

    }).then(function(response) {
        if (response.ok) {
          window.location.href = self.afloUrl + "Project";
          return;
        }
        throw new Error('Network response was not ok.');

    }).catch(function(err) { 
        console.log("error: " + err);
        self.logout();
        window.location.href = self.loginUrl;
    });
  }

  //-----------------------------------------------------------------------------
  // トークンが期限切れかを確認します
  //
  isTokenValid(token) {
    var ret = false;
    try {
      var base64Url = token.split('.')[1];
      var decodedValue = JSON.parse(window.atob(base64Url));
      console.log(decodedValue);
      var cur_time = new Date() / 1000.0;
      if (cur_time < decodedValue.exp) {
        ret = true;
      }  
    }
    catch(e) {
      
    }
    return ret;
  }
  //-----------------------------------------------------------------------------
  // トークンが有効の場合に、ユーザー情報を復元します
  //
  restoreUserInfo() {
    let ret = false;
     try {
       // const { cookies } = this.props;
       let str = localStorage.getItem('aac_login_user_info');
       // let str = cookies.get('aac_login_user_info');
       let arr = this.hex2bin(str);
       let bf = new Blowfish('AeroAsahi.SmartMaintanancePlatform', Blowfish.MODE.ECB, Blowfish.PADDING.SPACES);
       let str2 = bf.decode(arr);
       let obj = JSON.parse(str2);
       let loginInfo = obj.loginInfo;
       let token = loginInfo.token;
       ret = this.isTokenValid(token);
       if (ret) {
         this.loginInfo = obj.loginInfo     ;
         this.curOrgIndex = obj.curOrgIndex   ;
         this.curServiceId = obj.curServiceId  ;
         this.curProjectInfo = obj.curProjectInfo;
         this.pointCloudInfo = obj.pointCloudInfo;
         this.storageInfo = obj.storageInfo   ;
         localStorage.removeItem('aac_login_user_info');
       }
       else {
         localStorage.removeItem('aac_login_user_info');
       }
     }
     catch(e) {
       console.log(e.toString());
       ret = false;
     }
    return ret;
  }

  //-----------------------------------------------------------------------------
  // 16進文字列に変換します。
  //
  bin2hex (arr) {
    var i
    var l
    var o = ''
    var n

    for (i = 0, l = arr.length; i < l; i++) {
      n = arr[i].toString(16);
      o += n.length < 2 ? '0' + n : n
    }

    return o
  }
  //-----------------------------------------------------------------------------
  // 16進文字列に変換します。
  //
  hex2bin (str) {
    let arr = new Uint8Array(str.length / 2);
    let index = 0;
    for (let i = 0, l = str.length; i < l; i+=2) {
      let num = parseInt(str.substr(i, 2), 16);
      arr[index++] = num;
    }
    return arr;
  }

  //---------------------------------------------------------------------
  // ファイル・サイズ
  sizeFormatter(cell, row) {
    let formatter = new Intl.NumberFormat('ja-JP');

    //---------------------------------------------------------------------
    // 小数点以下ｎ桁の丸め関数です
    let round = function(num, n) {
      var tmp = Math.pow(10, n);
      return Math.round(num * tmp) / tmp;
    }

    let limitKB = 1024;
    let limitMB = 1024 * 1024;
    let limitGB = limitMB * 1024;
    let limitTB = limitGB * 1024;
    let str = '';
    let size = 0;
    if (cell < limitKB) {
      size = cell;    
      str = size.toString();
      str = formatter.format(str) + " Byte";
    }
    else if (cell < limitMB) {
      // KB
      size = round(cell / 1024.0, 1);    
      str = size.toString();
      str = formatter.format(str) + " KB";
    }
    else if (cell < limitGB) {
      // MB
      size = round(cell / limitMB, 2);    
      str = size.toString();
      str = formatter.format(str) + " MB";
    }
    else if (cell < limitTB) {
      // GB
      size = round(cell / limitGB, 3);    
      str = size.toString();
      str = formatter.format(str) + " GB";
    }
    else {
      // TB
      size = round(cell / limitTB, 3);    
      str = size.toString();
      str = formatter.format(str) + " TB";
    }
    return (
      <div>{str}</div>
    );
  }

}

export default withCookies(App);
