import React, { Component } from 'react';
import { withRouter } from 'react-router';

import GeneralFileList from './generalFileList';
import GeneralFileGroupList from './generalFileGroupList';
import MenuGeneral from './menuGeneral';

import iconBatsu from './images/white_batsu.svg';
import iconUpload from './images/upload.svg';
import iconUpdate from './images/re-load.svg';
import iconTrash from './images/trash.svg';
import iconProperty from './images/property.svg';
import iconConvert from './images/convert_for_viewer.svg';

//--- Point Clouds --------------------------------------------
class MgmtDemFiles extends Component {

  constructor(props, context){
    super(props, context);

    // 0: リスト
    // 1: グループ
    this.state = {
      dispType: 0,
      searchText: '',
      isUploadDialogVisible: false,
      isGroupDialogVisible: false,
      rowIndex: -1,
      rowInfo: null,
      isRowMenuVisible: false
    };

    this.rowMenuTop = 0;
    this.rowMenuLeft = 0;

    this.fileType = "Ortho";

    this.myApp = this.props.myApp;
    this.parent = this.props.parent;
    this.fileInfo = [];
    let arr = this.myApp.fileList["Dem"];
    if (!arr) {
      return;
    }
    for (let i = 0; i < arr.length; i++) {
      let info = arr[i];
      let size = 0;
      if (typeof info.FileSize === "string") {
        let str = info.FileSize.trim().replace(/,/g,'');
        size = Number(str);  
      } else {
        size = info.FileSize;
      }
      info.FileSize = size;
      // console.log("+ size = " + size);
      let obj = {id:i, FileName:info.FileName, FileUrl:info.FileUrl, FileSize:size, GroupName:info.GroupName, menu:true};
      this.fileInfo.push(obj);
    }
    this.orgOrthoInfo = this.fileInfo;
    this.flag = false;
  }

  //-----------------------------------------------------------
  componentDidMount() {
    document.addEventListener('contextmenu', this._handleContextMenu);
    document.addEventListener('click', this._handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this._handleContextMenu);
    document.removeEventListener('click', this._handleClick);
  }

  _handleClick = (event) => {
    const clickX = event.clientX;
    const clickY = event.clientY;
    console.log("+[MgmtOrthoImages] clickX: " + clickX + ", clickY: " + clickY);

    if (this.state.isRowMenuVisible) {
      this.setState({rowIndex:-1, isRowMenuVisible:false});
    }
  };

  _handleContextMenu = (event) => {
      event.preventDefault();
  }

  onClickRowMenu(e, rowIndex, row) {
    // ポップアップメニュー
    if (this.state.isRowMenuVisible) {
      return;
    }
    e.nativeEvent.stopPropagation();
    document.removeEventListener('click', this._handleClick);
    
    let ret = this.parent.calcMenuPosition(e);
    this.rowMenuTop = ret.top;
    this.rowMenuLeft = ret.left;

    this.setState({rowIndex:rowIndex, rowInfo:row, isRowMenuVisible:true});
  }

  onClickIcon(event) {
    let str = event.currentTarget.getAttribute('data-index');
    console.log("+++ data-index = " + str);
    let id = Number(str);
    if (id === 2) {
      // アップロード
      this.parent.dispDialog(1);
    }
    else if (id === 4) {
      // 更新

    }
    else if (id === 5) {
      // 削除

    }
    else if (id === 6) {
      // プロパティ

    }
    else if (id === 7) {
      // ビューア変換
      this.parent.dispDialog(16);
    }
  }

  onClickDispType(event) {
    let str = event.currentTarget.getAttribute('data-index');
    console.log("+++ data-index = " + str);
    let dispType = Number(str);
    if (this.state.dispType !== dispType) {
      this.setState({dispType: dispType});
      this.parent.dispType = dispType;
    }
  }

  onChangeSearchText(event) {
    console.log("+++ searchText:" + event.target.value);
    let searchText = event.target.value;
    let arr = this.orgOrthoInfo;
    if (arr) {
      this.fileInfo = [];
      for (let i = 0; i < arr.length; i++) {
        let info = arr[i];
        if (info.FileName.indexOf(searchText) === 0) {
          this.fileInfo.push(arr[i]);
        }
      }        
    }

    this.setState({searchText: event.target.value});
  }

  dispType() {
    if (this.state.dispType === 0) {
      return (
        <div style={{display:'inline-block'}}>
          <div className="itemList" style={{background:'#5e9ad6'}} data-index="0" onClick={this.onClickDispType.bind(this)}>リスト</div>
          <div className="itemGroup" data-index="1" onClick={this.onClickDispType.bind(this)}>グループ</div>
        </div>
      );
    }
    else {
      return (
        <div style={{display:'inline-block'}}>
          <div className="itemList" data-index="0" onClick={this.onClickDispType.bind(this)}>リスト</div>
          <div className="itemGroup" style={{background:'#5e9ad6'}} data-index="1" onClick={this.onClickDispType.bind(this)}>グループ</div>
        </div>
      );
    }
  }

  render() {
    let Content = <div style={{display:'none'}}></div>;
    if (this.state.dispType === 0) {
      // リスト 
      Content = (() => <GeneralFileList info={this.fileInfo} parent={this} myApp={this.props.myApp}/>);
    }
    else {
      // グループ
      Content = (() => <GeneralFileGroupList info={this.fileInfo} parent={this} myApp={this.props.myApp}/>);
    }

    let RowMenu = (() => <div style={{display:'none'}}></div>);
    if (this.state.isRowMenuVisible) {
      RowMenu = (() => <MenuGeneral type={this.state.curPage} parent={this} rowIndex={this.state.rowIndex} rowInfo={this.state.rowInfo}
                          rowMenuTop={this.rowMenuTop} rowMenuLeft={this.rowMenuLeft} fileType={this.fileType} {...this.props}/>);
      document.addEventListener('click', this._handleClick);
    }

    let titleStr = this.props.title;

    return (
      <div>
        <div className="mgmtContentHeader">
          <p className="mgmtTitle1">{titleStr}</p>
          <p className="mgmtTitle2" style={{fontSize:'11px'}}>ディレクトリィ ~</p>
          <img width="16" height="16" src={iconBatsu} 
               style={{position:'absolute', cursor:'pointer', top:'8px', right:'8px'}} alt="閉じる" data-index="1" 
               onClick={this.onClickIcon.bind(this)}/>
        </div>
        <div id="mgmtContent" className="mgmtContent">
          { /* --- ツール・バー --- */ }
          <div className="mgmtToolbar">
            <div className="mgmtToolbarItem" data-index="2" onClick={this.onClickIcon.bind(this)} style={{display:'none'}}>
              <img width="20" height="20" src={iconUpload} alt="アップロード"/>
              <span>アップロード</span>
            </div>

            { /* --- グループ設定は「PointClouds」の場合のみ利用可能 --- */ }

            <div className="mgmtToolbarItem" data-index="4" onClick={this.onClickIcon.bind(this)} style={{display:'none'}}>
              <img width="20" height="20" src={iconUpdate} alt="更新"/>
              <span>更新</span>
            </div>
            <div className="mgmtToolbarItem" data-index="5" onClick={this.onClickIcon.bind(this)} style={{display:'none'}}>
              <img width="20" height="20" src={iconTrash}  alt="削除"/>
              <span>削除</span>
            </div>
            <div className="mgmtToolbarItem" style={{marginLeft:'20px'}} data-index="6" onClick={this.onClickIcon.bind(this)} style={{display:'none'}}>
              <img width="20" height="20" src={iconProperty} alt="プロパティ"/>
              <span>プロパティ</span>
            </div>
            <div className="mgmtToolbarItem" style={{marginLeft:'16px'}} data-index="7" onClick={this.onClickIcon.bind(this)} style={{display:'none'}}> 
              <img width="24" height="24" src={iconConvert}  alt="ビューア変換"/>
              <span>ビューア変換</span>
            </div>
          </div>

          { /* --- 表示切替 は「PointClouds」の場合のみ利用可能 --- */ }
          <div style={{margin:'12px 20px'}}></div>

          { /* --- ファイル検索 --- */ }
          <div style={{marginLeft:'32px', marginRight:'32px', fontSize:'12px'}}>
            <input className="form-control glyphicon" type="search" 
                   placeholder="&#57347; プレフィックスでのファイル検索" style={{padding:'4px 10px'}} 
                   onChange={this.onChangeSearchText.bind(this)}
                   value={this.state.searchText}/>
          </div>

          { /* --- ファイル一覧 --- */ }          
          <Content />

          { /* --- ポップアップメニュー --- */ }          
          <RowMenu />
        </div>
      </div>
    );
  }
}

export default withRouter(MgmtDemFiles);