import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Head02 from './head02';
//import PointCloudList from './pointCloudList';
//import PointCloudGroupList from './pointCloudGroupList';
import MyFileUpload from './fileUpload';
import GroupSetting from './groupSetting';
import GroupMgmt from './groupMgmt';
import GroupAdd from './groupAdd';
import ViewerConvert from './viewerConvert';
import TileConvert from './tileConvert';

import MgmtPointClouds from './mgmtPointClouds';
import MgmtOrthoPhotos from './mgmtOrthoPhotos';
import MgmtDemFiles    from './mgmtDemFiles';
import Mgmt3dModels    from './mgmt3dModels';
import MgmtShapeFiles  from './mgmtShapeFiles';


//import iconBatsu from './images/white_batsu.svg';
//import iconUpload from './images/upload.svg';
//import iconPlus from './images/gray_plus.svg';
//import iconUpdate from './images/re-load.svg';
//import iconTrash from './images/trash.svg';
//import iconProperty from './images/property.svg';
//import iconConvert from './images/convert_for_viewer.svg';

//--- サイド・バー --------------------------------------------
class SideMenu extends Component {
  constructor(props, context){
    super(props, context);
    this.state = {
        selectedMenu: this.props.menuId
    };

    this.parent = this.props.parent;
    this.myApp = this.props.myApp;

    this.fileTypes = [
      "Las", "Ortho", "Dem", "3dModel", "Shape"
    ];

    this.items = [
      {title: "Point Clouds",          pageId: 0},
      {title: "航空写真（オルソ）",    pageId: 1},
      {title: "DEM（数値標高モデル）", pageId: 2},
      {title: "3Dモデル",              pageId: 3},
      {title: "Shapefile",             pageId: 4},
      {title: "設定",                  pageId: 5}
    ];
  }

  onSelectMenu(event) {
    //--- メニューの選択 --------------------------------------

    let str = event.currentTarget.getAttribute('data-index');
    console.log("+++ data-index = " + str);
    let index = Number(str);
    if (index === this.state.selectedMenu) {
      return;
    }

    /*
    ・las/lazファイル：”Las”
    ・Shapeファイル：“Shape”
    ・Demファイル：”Dem”
    ・オルソファイル：”Ortho”
    ・3Dモデル：”3dModel”
    */

    if (index === 5) {
      // 設定
      this.setState({selectedMenu:index});
      this.parent.setState({curPage:index});
      return;
    }

    var ftype = this.fileTypes[index];

    this.parent.setState({fCommExec:true});
    var self = this;

    //変換ダイアログが表示されている場合
    if(this.parent.state.dialogId !== 0){
      //pointcloudメニュー選択の際はoctree変換ダイアログ表示
      if(index === 0 ){this.parent.dispDialog(16);}
      //オルソメニュー選択の際はタイル変換ダイアログ表示
      else if(index === 1){this.parent.dispDialog(32);}
      //現状その他はダイアログを閉じる
      else{this.parent.hideDialog(64)}
    }

    if (this.myApp.fileList[ftype] !== undefined && this.myApp.fileList[ftype] != null ) {
      setTimeout( function() {
        self.setState({selectedMenu:index});
        self.parent.setState({curPage:index, fCommExec:false});
      }, 500);
      return;
    }

    var pid = this.myApp.curProjectInfo.ProjectId;
    this.myApp.getFileList(pid, ftype, function(ret, json) {
      setTimeout( function() {
        self.setState({selectedMenu:index});
        self.parent.setState({curPage:index, fCommExec:false});
      }, 500);
    });
  }

  renderItems() {
    let list = this.items;
    let index = -1;
    return list.map((item) => {
      index += 1;
        return (
          <div key={item.pageId} className={(index === this.state.selectedMenu)?"mgmtMenuItem selected":"mgmtMenuItem"} data-index={""+item.pageId} onClick={this.onSelectMenu.bind(this)}>
            <span style={{userSelect:'none'}}>{item.title}</span>
          </div>
        );
    });
  }

  render() {
    return (
      <div>
        <div className="mgmtHeader" style={{width:'250px', borderRight:'solid 2px #555'}}>
          <p className="mgmtTitle1">データセット管理</p>
          <p className="mgmtTitle2">{this.myApp.curProjectInfo.ProjectName}</p>
        </div>
        <div className="mgmtMenu">
          {this.renderItems()}
        </div>
      </div>
    );
  }
}

//--- データセット管理 ----------------------------------------
class DataSetMgmt extends Component {

  constructor(props, context){
    super(props, context);

    let userName = "";
    if (this.props.loginInfo) {
      let userInfo = this.props.loginInfo.User;
      // let orgInfo = userInfo.SmpfGroups[this.props.orgIndex];
      //--- let orgInfo = this.props.userInfo[this.props.orgIndex];
      let mail = userInfo.DisplayName;  // orgInfo.mail;
      let wi = mail.indexOf('@');
      if (wi < 0)
        userName = mail;
      else
        userName = mail.substr(0, wi);
    }

    //--- For Debug ---
    this.groups = [
      {id:0, title:"GROUP1"},
      {id:1, title:"GROUP2"},
      {id:2, title:"GROUP3"},
      {id:3, title:"GROUP4"},
      {id:4, title:"GROUP5"},
      {id:5, title:"GROUP6"},
      {id:6, title:"GROUP7"},
      {id:7, title:"GROUP8"},
      {id:8, title:"GROUP9"},
      {id:9, title:"GROUP10"}
    ];
    //---

    //--- curPage ---
    // 0: Point Clouds
    // 1: 航空写真（オルソ）
    // 2: DEM（数値標高モデル）
    // 3: 3Dモデル
    // 4: Shapefile
    // 5: 設定

    this.subTitles = [
      "Point Clouds",
      "航空写真（オルソ）",
      "DEM（数値標高モデル）",
      "3Dモデル",
      "Shapefile",
      ];

    this.fileTypes = [
        "Las", "Ortho", "Dem", "3dModel", "Shape"
      ];

    this.dispType = 0;  // 0:リスト, 1:グループ

    //--- dialogId ... ダイアログの表示は排他とする。グループ系は同時表示。
    // 1: ファイルのアップロード
    // 2: グループ設定
    // 4: グループの管理
    // 8: グループの作成
    this.state = {
      userName: userName,
      curPage: 0,
      dialogId: 0,
      fCommExec: false
    };
  }

  //-----------------------------------------------------------
  componentDidMount() {
    document.documentElement.lang = "ja"; 
    document.addEventListener('contextmenu', this._handleContextMenu);
    document.addEventListener('click', this._handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this._handleContextMenu);
    document.removeEventListener('click', this._handleClick);
  }

  _handleClick = (event) => {
    const clickX = event.clientX;
    const clickY = event.clientY;
    console.log("+ clickX: " + clickX + ", clickY: " + clickY);
  };

  _handleContextMenu = (event) => {
      event.preventDefault();

//      const clickX = event.clientX;
//      const clickY = event.clientY;
//      const screenW = window.innerWidth;
//      const screenH = window.innerHeight;
//      let root = document.getElementById('root');
//      const rootW = root.offsetWidth;
//      const rootH = root.offsetHeight;
//      
//      const right = (screenW - clickX) > rootW;
//      const left = !right;
//      const top = (screenH - clickY) > rootH;
//      const bottom = !top;
//      console.log("+ t:" + top + ", b:" + bottom + ", l:" + left + ", r:" + right);
  }

  //--- ログアウト --------------------------------------------
  logout() {
    this.props.myApp.logout();
    this.props.history.push('/');
  }

  //--- ポータル画面へ移動 ------------------------------------
  toPortal() {
    this.props.history.push('/portal/' + this.props.serviceId);
  }

  //--- プロジェクト一覧画面へ移動 ------------------------------------
  toDataSetList() {
    this.props.history.push("/dataSetList");
  }  

  //--- ページ選択 --------------------------------------------
  changePage(pageId) {
    this.setState({curPage: pageId});
  }

  //--- メニューの位置 ----------------------------------------
  calcMenuPosition(e) {

    let wdiv = document.getElementById("mgmtContent");
    let wx = e.nativeEvent.layerX;
    let wy = e.nativeEvent.layerY;
    
    let ret = {};

    if (wy + 128 > wdiv.clientHeight) {
      ret.top = wy - 128;
    } else {
      ret.top = wy;
    }
    ret.top += wdiv.scrollTop;
    ret.left = wx - 280;

    return ret;
  }

  //--- ダイアログ --------------------------------------------
  dispDialog(id) {
    // 1: ファイルのアップロード
    // 2: グループ設定
    // 4: グループの管理
    // 8: グループの作成
    //16: ビューア変換
    //32: タイル変換

    if (this.state.dialogId === id) {
      return;
    }
    if (this.state.dialogId & id) {
      return;
    }

    if (this.state.dialogId === 0) {
      this.setState({dialogId:id});
    }
    else if (this.state.dialogId === 1) {
      this.setState({dialogId:id});
    }
    else if (this.state.dialogId === 16) {
      this.setState({dialogId:id});
    }
    else if (this.state.dialogId === 32){
      this.setState({dialogId:id})
    }
    else {
      if (id === 1 || id === 16|| id === 32) {
        this.setState({dialogId:id});
      }
      else {
        let wid = this.state.dialogId | id;
        this.setState({dialogId:wid});
      }
    }
  }

  hideDialog(id) {
    let wid = this.state.dialogId - id;
    if (wid < 0) {
      wid = 0;
    }
    this.setState({dialogId:wid});
  }

  //--- 描画 --------------------------------------------------
  render() {
    let Content = null;
    let title = this.subTitles[this.state.curPage];
    if (this.state.curPage === 0) {
      // Point Clouds
      Content = (() => <MgmtPointClouds parent={this} title={title} type={this.dispType} {...this.props}/>);
    }
    else if (this.state.curPage === 1) {
      // オルソ画像
      Content = (() => <MgmtOrthoPhotos parent={this} title={title} type={this.dispType} {...this.props}/>);
    }
    else if (this.state.curPage === 2) {
      // DEM
      Content = (() => <MgmtDemFiles parent={this} title={title} type={this.dispType} {...this.props}/>);
    }
    else if (this.state.curPage === 3) {
      // 3D Model
      Content = (() => <Mgmt3dModels parent={this} title={title} type={this.dispType} {...this.props}/>);
    }
    else if (this.state.curPage === 4) {
      // Shapefile
      Content = (() => <MgmtShapeFiles parent={this} title={title} type={this.dispType} {...this.props}/>);
    }
    else if (this.state.curPage === 5) {
      // 設定
      Content = (() => <div style={{display:'none'}}></div>);
    }

    let MyDialog1 = (() => <div style={{display:'none'}}></div>);
    let MyDialog2 = (() => <div style={{display:'none'}}></div>);
    let MyDialog4 = (() => <div style={{display:'none'}}></div>);
    let MyDialog8 = (() => <div style={{display:'none'}}></div>);
    let MyDialog16 = (() => <div style={{display:'none'}}></div>);
    let MyDialog32 = (() => <div style={{display:'none'}}></div>);
    if (this.state.dialogId === 1) {
      MyDialog1 = (() => <MyFileUpload type={this.state.curPage} parent={this} subTitle={this.subTitles[this.state.curPage]} {...this.props}/>);
    }
    else if (this.state.dialogId === 16) {
      MyDialog16 = (() => <ViewerConvert ftype={this.fileTypes[this.state.curPage]} curpage={this.state.curPage} parent={this} subTitle={this.subTitles[this.state.curPage]} {...this.props}/>);
    }
    else if (this.state.dialogId === 32) {
      MyDialog32 = (() => <TileConvert ftype={this.fileTypes[this.state.curPage]} curpage={this.state.curPage} parent={this} subTitle={this.subTitles[this.state.curPage]} {...this.props}/>);
    }
    else if (this.state.dialogId >= 2) {
      if (this.state.dialogId & 2) {
        MyDialog2 = (() => <GroupSetting curPage={this.state.curPage} parent={this} subTitle={this.subTitles[this.state.curPage]} 
                                        groups={this.groups} {...this.props}/>);
      }
      if (this.state.dialogId & 4) {
        MyDialog4 = (() => <GroupMgmt type={this.state.curPage} parent={this} subTitle={this.subTitles[this.state.curPage]} 
                                        groups={this.groups} {...this.props}/>);
      }
      if (this.state.dialogId & 8) {
        MyDialog8 = (() => <GroupAdd type={this.state.curPage} parent={this} subTitle={this.subTitles[this.state.curPage]} 
                                        groups={this.groups} {...this.props}/>);
      }
    }

    let spinnerStyle = {display:'none'};
    if (this.state.fCommExec) {
      spinnerStyle = {display:'block', zIndex:'1000'};
    }
    
    return (
      <div className="App">
        <Head02 onLogout={this.logout.bind(this)} toPortal={this.toDataSetList.bind(this)} {...this.props}/>

        <SideMenu menuId={this.state.curPage} userName={this.state.userName} parent={this} {...this.props}/>

        <div className="contents_center" style={{margin:'0', padding:'0', top:'56px', left:'250px', bottom:'0px', right:'0px', width:'auto', overflow:'hidden'}}>
          <Content />

          <MyDialog1 />
          <MyDialog2 />
          <MyDialog4 />
          <MyDialog8 />
          <MyDialog16 />
          <MyDialog32/>

          <div className="spinner-loader" style={spinnerStyle}></div>
        </div>
      </div>
    );
  }

}

export default withRouter(DataSetMgmt);


