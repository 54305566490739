import React, { Component } from 'react';

class MenuPointClouds extends Component {

  constructor(props){
    super(props);
    this.state = {
      
    };

    this.menuTop = this.props.rowMenuTop;
    this.menuLeft = this.props.rowMenuLeft;

    this.fileInfo = this.props.rowInfo;
    this.myApp = this.props.myApp;

    this.items = [
      {id:0, title: "ダウンロード", icon:"glyphicon glyphicon-save"},
      {id:1, title: "プロパティ",   icon:"glyphicon glyphicon-th-list"},
      {id:2, title: "削除",         icon:"glyphicon glyphicon-trash"}
    ];
  }

  onClickMenu(event) {
    let str = event.currentTarget.getAttribute('data-index');
    let index = Number(str);
    if (index === 0) {
      // ダウンロード
      this.myApp.downloadFile(this.fileInfo);
    }
    else if (index === 1) {
      // プロパティ
    }
    else if (index === 2) {
      // 削除
    }
  }

  renderItems() {
    return this.items.map((item) => {
      return (
        <div key={item.id.toString()} onClick={this.onClickMenu.bind(this)} data-index={item.id.toString()} className="my_menu_item" style={{textAlign:'center'}}>
          <div style={{padding:'2px 0px'}}>
            <div style={{textAlign:'left', width:'200px', display:'inline-block'}}>{item.title}</div>
            <div style={{textAlign:'center', verticalAlign:'middle', width:'24px', display:'inline-block'}}>
              <span className={item.icon} aria-hidden="true"></span>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    let wt = "" + this.menuTop + "px";
    let wl = "" + this.menuLeft + "px";
    return (
      <div id="menuPointClouds" className="my_popup_menu" style={{textAlign:'left', zIndex:'1000', top:wt, left:wl}}>
        {this.renderItems()}
      </div>
    );
  }

}

export default MenuPointClouds;

