import React, { Component } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import iconBatsu from './images/white_batsu.svg';

class MyFileUpload extends Component {
  constructor(props, context) {
    super(props, context);

    // Type: 0 ... Point Clouds
    //       1 ... オルソ画像
    //       2 ... DEM
    //       3 ... Shapefile
    this.state = {
      type: this.props.type,
      subTitle: this.props.subTitle,
      selectedFile: null,
      loaded: 0,
      progressVisible: false
    };

    this.parent = this.props.parent;
    this.uploadButton = null;
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.checkChangedHandler = this.checkChangedHandler.bind(this);
  }

  fileChangedHandler = (event) => {
    this.setState({selectedFile: event.target.files[0]});
  }

  checkChangedHandler = (event) => {
    
  }

  round = (num, n) => {
    var tmp = Math.pow(10, n);
    return Math.round(num * tmp) / tmp;
  }
  
  uploadHandler = () => { 
    console.log(this.state.selectedFile);

    //TODO：API作成
    let url = "http://localhost/dev3/upload.php";

    const data = new FormData();
    data.append('upfile', this.state.selectedFile, this.state.selectedFile.name);
    this.setState({progressVisible: true});

    axios
      .post(url, data, {
        onUploadProgress: ProgressEvent => {
          console.log("+ loaded ... " + ProgressEvent.loaded);
          let loaded = this.round(ProgressEvent.loaded / ProgressEvent.total * 100, 1);
          this.setState({ loaded: loaded })
        },
      })
      .then(res => {
        console.log(res.statusText);
        alert("アップロードが完了しました。");
        this.setState({progressVisible: false, selectedFile: null});
        document.getElementById("upfile").value = "";
      })
      .catch(function(error) {
        alert("アップロード中、エラーが発生しました。\n" + error);
      });
}

  onClickClose = () => {
    this.parent.hideDialog(1);
  }

  render() {
    let Btn = null;
    if (this.state.selectedFile) {
      Btn = (() => <Button bsStyle="primary" onClick={this.uploadHandler}>アップロード</Button>);
    }
    else {
      Btn = (() => <Button bsStyle="default" disabled>アップロード</Button>);
    }

    let Progress = (() => <div style={{display:'none'}}></div>);
    if (this.state.progressVisible) {
      Progress = (() => <ProgressBar now={this.state.loaded} label={`${this.state.loaded}%`}/>);
    }

    return (
      <div className="formMyDialog">
        <div className="dialogHeader">
          <p className="dialogTitle1">ファイルのアップロード</p>
          <p className="dialogTitle2">{this.state.subTitle}</p>
          <img width="14" height="14" src={iconBatsu} 
               style={{position:'absolute', cursor:'pointer', top:'8px', right:'8px'}} alt="閉じる" data-index="1" 
               onClick={this.onClickClose.bind(this)}/>
        </div>
        
        <div className="dialogContent" style={{display:'block'}}>
          <label htmlFor="fileForUpload" style={{marginTop:'10px'}}>
            <span style={{display:'inline-block', marginRight:'8px'}}>ファイル</span>
            <span className="glyphicon glyphicon-info-sign" aria-hidden="true" style={{display:'inline-block', fontSize:'16px'}}></span>
          </label>
          <div style={{marginTop:'4px'}}>
            <form id="content" encType="multipart/form-data" method="post" action="">
              <input type="file" id="upfile" name="upfile" placefolder="ファイルの選択" onChange={this.fileChangedHandler}/>
            </form>            
          </div>
          <div style={{marginTop:'12px'}}>
            <label>
              <input type="checkbox" id="forceOverwrite" onChange={this.checkChangedHandler}/>
              <span style={{fontSize:'12px', marginLeft:'4px', marginTop:'-2px', userSelect:'none'}}>ファイルが既に存在する場合は上書きする</span>
            </label>
          </div>
          <div style={{marginTop:'12px'}}>
            <Btn />
          </div>
          <div style={{marginTop:'12px'}}>
            <Progress />
          </div>
        </div>
      </div>
    );
  }
}

export default MyFileUpload;
