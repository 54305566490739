import React, { Component } from 'react';
import logo from './images/company.png';

class Footer extends Component {

  render() {
    let aStyle = {right:"10px"};
    return (
        <div className="footer">
          <a style={aStyle} href="https://www.aeroasahi.co.jp/">
            <img src={logo} className="companylogo" alt="朝日航洋株式会社" />
          </a>
        </div>
    );
  }
}

export default Footer;
