import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Head02 from './head02';
import Footer from './footer';

//--- データセット一覧 ------------------------------------------------
class DataSetList extends Component {

  constructor(props, context){
    super(props, context);

    this.state = {
      orgIndex: this.props.orgIndex
    };

    this.orgIndex = this.props.orgIndex;
    this.projInfo = null;
    if (this.props.loginInfo) {
      let userInfo = this.props.loginInfo.User;
      this.projInfo = userInfo.SmpfGroups[this.orgIndex].SmpfProject;

      let wh = 60 + 50 * (this.projInfo.length + 1) + 84;
      this.listHeight = wh;
    }
    this.clientHeight = this.props.myApp.clientHeight;
    this.onSelectProject = this.props.onSelectProject;
    this.onSelectApplication = this.props.onSelectApplication;
    this.onConfigJsonLoaded = this.onConfigJsonLoaded.bind(this);
    this.onAccesskeyLoaded = this.onAccesskeyLoaded.bind(this);
  }
  
  //-----------------------------------------------------------
  componentDidMount() {
    document.documentElement.lang = "ja"; 
  }

  //--- ログアウト --------------------------------------------
  logout() {
    this.props.myApp.logout();
    this.props.history.push('/');
  }

  //--- ポータル画面へ移動 ------------------------------------
  toPortal() {
    this.props.history.push('/portal/' + this.props.serviceId);
  }

  //--- プロジェクト一覧画面へ移動 ------------------------------------
  toDataSetList() {
    this.props.history.push("/dataSetList");
  }
    
  //--- プロジェクトの選択 ------------------------------------
  onSelectProjectDsl(event) {
    let str = event.currentTarget.getAttribute('data-index');
    let index = Number(str);
    console.log("+++ onSelectProject ... " + index);

    if (index < this.projInfo.length) {
      let info = this.projInfo[index];
      
      console.log("+++ title ... " + info.ProjectName);
      this.onSelectProject(info);
  
      this.curProjInfo = info;      
      this.props.myApp.getAccesskey(this.curProjInfo, this.onAccesskeyLoaded);        
      //CallBackとつなげるとcookieの挙動がおかしくなるためここに配置
      this.props.myApp.getProjectConfig(this.curProjInfo, this.onConfigJsonLoaded);
    }
    else {
      // プロジェクト管理
    }
  }

  onAccesskeyLoaded(){
    var a;
    if (this.curProjInfo.storage.storagekey) {     
      this.onSelectApplication(this.curProjInfo);       
    }
    else {
      alert('accesskey を読み込めませんでした。');
    }
  }

  onConfigJsonLoaded() {
    if (this.curProjInfo.configJson && this.curProjInfo.configJson.ROOT) {      
      this.props.history.push('/projectMenu');  
    }
    else {
      alert('config.json を読み込めませんでした。');
    }
  }
  //--- 組織の選択 --------------------------------------------
  onSelectOrgDsl(index) {
    console.log("+++ onSelectOrgDsl ... " + index);
    let userInfo = this.props.loginInfo.User;
    this.projInfo = userInfo.SmpfGroups[index].SmpfProject;
    // this.projInfo = this.props.userInfo[index];
    if (this.projInfo.length >= 10) {
      this.topPos = 100;
    }
    this.orgIndex = index;

    this.setState({orgIndex:index});
    this.props.onSelectOrg(index);
  }

  //--- 一覧の項目を描画 --------------------------------------
  renderItems() {
    if (!this.projInfo) {
      return;
    }
    let list = this.projInfo;
    let index = -1;
    return list.map((item) => {
      item.link = "";
      index += 1;
      return (
        <div key={index.toString()}>
          <div className="my_list_item">
            <span className="my_list_item_title" data-index={""+index} style={{display:'inline-block', width:'580px', marginTop:'4px'}} onClick={this.onSelectProjectDsl.bind(this)}>{item.ProjectName}</span>
          </div>
          <div className="my_line"></div>
        </div>
      );
    });
}

  //--- 描画 --------------------------------------------------
  render() {
    let lastIndex = 0;
    if (this.projInfo) {
      lastIndex = this.projInfo.length;
    }
    let str = '8px';
    if (this.clientHeight > this.listHeight) {
      str = "calc(calc(100% - " + this.listHeight + "px) / 2)";
    }    
    let wstyle = {top:str};
    
    return (
      <div className="App">

        <Head02 onLogout={this.logout.bind(this)} toPortal={this.toDataSetList.bind(this)} onSelectOrgDsl={this.onSelectOrgDsl.bind(this)} {...this.props}/>
        <div className="divideline clearfix"></div>

        <div id='contents_center' className="contents_center" style={{margin:'auto'}}>
          <div className="project-list" style={wstyle}>
            <div className="project-list-header">
              <span className="glyphicon glyphicon-list-alt" aria-hidden="true"></span>&nbsp;<span>プロジェクト一覧</span>
            </div>
            {this.renderItems()}
            <div >
              <div className="my_list_item">
                <span className="my_list_item_title" data-index={""+lastIndex} style={{display:'inline-block', width:'580px', marginTop:'4px'}} onClick={this.onSelectProjectDsl.bind(this)}>プロジェクト管理</span>
              </div>
              <div className="my_line"></div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(DataSetList);

